<template>
  <div class="no-xhide">
    <div data-aos="fade-up" class="pos-rlt d-none d-md-flex">
      <img class="hero-pg-img" src="@/assets/invest-bg-pg.svg" width="100%" alt="invest-bg-pg">
      <div class="invst-pg-ctn pos-abs">
        <p class="invst-ctn-title font-weight-bold fs-54 lh-58 white--text">Hassle-free <br> investing</p>
        <p class="white--text headline font-weight-thin mb-2">Stocks, IPOs, bonds and US stocks <br> are just a click
          away.</p>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">
          <v-row no-gutters class="pt-10">
            <v-col cols="9">
              <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()" :maxlength="10" :rules="numberis" required
                hide-spin-buttons flat solo background-color="#ffffff"
                class="menu-field-num elevation-0 mr-3 rounded-pill caption" label="Enter mobile number to begin">
                <template #prepend-inner>
                  <img src="@/assets/phone-icon.svg" width="100%" class="mx-2" alt="phone-icon">
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-btn type="submit" :disabled="!valid" block height="48px" color="#FFF07E"
                class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
                  up</span></v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
    <div data-aos="fade-up" class="d-md-none">
      <div class="text-center pt-16 pos-rlt px-6 px-sm-8 bgg-lin">
        <h1 class="hero-ctn-title mb-3">Hassle - free <br> investing</h1>
        <p class="white--text title font-weight-regular mb-8">Advanced tools that are fast, easy to use and secure.</p>

        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()" class="pb-16">
          <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()" :maxlength="10" :rules="numberis" required
            hide-spin-buttons flat solo background-color="#ffffff"
            class="menu-field-num elevation-0 rounded-pill caption" label="Enter mobile number to begin">
            <template #prepend-inner>
              <img src="@/assets/phone-icon.svg" width="100%" class="mx-2" alt="phone-icon">
            </template>
          </v-text-field>

          <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
            class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
              up</span></v-btn>
        </v-form>

      </div>
    </div>
    <div data-aos="flip-up" class="sec-four py-0 py-md-2">
      <v-card class="rounded-c-pill pt-2 pb-1 px-md-3 elevation-0" color="#212424" width="100%">
        <div class="wrapper">
          <div class="secfour-scroll overflow-hidden">
            <p class="mb-0 white--text">
              <span v-for="(f, a) in secfouritems" :key="a">
                <span class="px-4 subtitle-2 text-uppercase font-weight-thin">{{ f.text }}</span>
                <img src="@/assets/sec-four-divider.svg" alt="sec-four-divider" class="mb-1">
              </span>
            </p>
            <p class="mb-0 white--text">
              <span v-for="(f, a) in secfouritems" :key="a">
                <span class="px-4 subtitle-2 text-uppercase font-weight-thin">{{ f.text }}</span>
                <img src="@/assets/sec-four-divider.svg" alt="sec-four-divider" class="mb-1">
              </span>
            </p>
          </div>
        </div>
      </v-card>
    </div>

    <div data-aos="fade-up" class="sec-three py-0 py-md-2">
      <v-card data-aos="zoom-in-down" color="#E5EBEC" class="secthree-bg rounded-xl pa-14 elevation-0 d-none d-md-block"
        width="100%">
        <p class="mb-0 secondary-font">Grow your wealth.<br>Everything you need.</p>
        <div class="mt-16">
          <v-row no-glutters>
            <v-col cols="3">
              <v-card @click="setinvestItem(n)" v-for="(n, d) in secthreeitems" :key="d"
                class="elevation-0 rounded-l-xl px-6 pt-3 pb-4 mb-8 text-center hcard"
                :class="inaid == n.id ? 'rounded-r-0' : 'rounded-r-xl'" width="100%">
                <img data-aos="zoom-in-down" class="py-2" :src="n.image" :alt="n.title" width="72%">
                <p class="font-weight-bold mb-0 fs-24">{{ n.title }}</p>
                <v-card class="hide-space-crd pos-abs rounded-0 elevation-0" width="20%" height="100%"
                  v-if="inaid == n.id"></v-card>
              </v-card>
            </v-col>
            <v-col cols="9" height="100%" class="colheightadd rounded-xl">
              <v-card width="100%"  class="rounded-r-xl elevation-0 py-10 px-16"
                :class="inaid == 1 ? 'rounded-tl-0 rounded-bl-xl' : 'rounded-xl'">
                <div>
                  <p class="font-weight-medium black--text"><span class="display-1">{{ investactive.tit }}</span></p>
                  <p class="txt-444 font-weight-light title lh-28">{{ investactive.sub }}</p>

                  <v-btn target="_blank" rel="noopener noreferrer" href="https://oa.mynt.in/?ref=zws" height="48px"
                    color="#2A2A2A" class="text-none rounded-pill elevation-0 mt-4 mb-10 hcardd"><span
                      class="white--text subtitle-1 font-weight-medium px-2">Start investing</span></v-btn>

                  <v-row>
                    <v-col v-for="(l, k) in investactive.data" :key="k" cols="6" :class="k % 2 == 0 ? 'pr-8' : 'pl-8'">
                      <img data-aos="fade-up" :alt="l.tit" :src="l.image" width="100%">
                      <div data-aos="fade-down">
                        <p class="font-weight-medium headline black--text">{{ l.tit }}</p>
                        <p class="font-weight-light mt-3 black--text fs-17">{{ l.sub }}</p>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>

          </v-row>
        </div>
      </v-card>
      <v-card data-aos="zoom-in-down" color="#E5EBEC"
        class="secthree-bg rounded-0 py-14 px-6 px-sm-8 elevation-0 d-md-none text-center" width="100%">
        <h4 class="mb-8 secondary-fn fs-32 font-weight-regular lh-32">Grow your wealth.
          Everything you need.</h4>

        <div class="d-inline-flex overflow-x-auto no-scroll pb-6 width-100">
          <div v-for="(l, o) in secthreeitems" :key="o">
            <v-btn @click="setinvestItem(l)" :color="inaid == l.id ? '#444444' : '#ffffff'"
              class="rounded-pill mr-2 text-none brd-c-2">
              <span :class="inaid == l.id ? 'white--text' : 'black--text'">{{ l.title }}</span>
            </v-btn>
          </div>
        </div>

        <div>
          <v-card width="100%" class="elevation-0 mx-auto rounded-xl px-6 px-sm-8">
            <img data-aos="zoom-in-down" class="mt-4" width="180px" :src="investactive.image" :alt="investactive.title">
            <p class="font-weight-bold fs-28">{{ investactive.title }}</p>
            <div>
              <p class="font-weight-medium fs-18 lh-16 mb-3">{{ investactive.tit }}</p>
              <p class="txt-444 font-weight-regular fs-16 lh-16">{{ investactive.sub }}</p>

              <v-btn target="_blank" rel="noopener noreferrer" href="https://oa.mynt.in/?ref=zws" height="48px"
                color="#2A2A2A" class="text-none rounded-pill elevation-0 mt-4 mb-8" block><span
                  class="white--text subtitle-1 font-weight-medium px-2">Start investing</span></v-btn>

              <v-row>
                <v-col v-for="(l, k) in investactive.data" :key="k" cols="12" class="text-left">
                  <img data-aos="fade-up" :alt="l.tit" :src="l.image" width="100%">
                  <div data-aos="fade-down">
                    <p class="font-weight-medium title black--text mb-2">{{ l.tit }}</p>
                    <p class="font-weight-light black--text fs-16">{{ l.sub }}</p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
      </v-card>
    </div>
    <div class="sec-seven pt-16">
      <v-card height="500px" class="mx-auto elevation-0 text-center d-none d-md-block" width="64%">
        <p class="secondary-font">Learn with Zebu</p>
        <p class="txt-444 font-weight-light title lh-28 mb-8">Investing and trading can be easy and accessible for
          <br>
          everyone now. Start by learning about how markets <br> and investing works.</p>

        <v-row justify="center">
          <v-col v-for="(s, n) in learnitems" :key="n" cols="12" md="6" class="px-4 pb-6">
            <v-card width="256px" :to="s.click" data-aos="zoom-in-up"
              class="secseven-card rounded-xl text-center pa-1 mx-auto hcard">
              <img data-aos="zoom-in-down" :src="s.image" width="160px" class="pa-4" :alt="s.title">
              <p class="font-weight-bold title mb-0">{{ s.title }}</p>
              <p class="txt-444 font-weight-regular caption text-uppercase">{{ s.subtitle }}</p>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-card height="500px" class="mx-auto elevation-0 text-center d-md-none px-6 px-sm-8" width="100%">
        <p class="secondary-font">Learn with Zebu</p>
        <p class="txt-444 font-weight-light fs-18 lh-28 mb-8">Investing and trading can be easy and accessible for
          everyone now. Start by learning about how markets and investing works.</p>

        <v-row justify="center" class="px-2 px-md-0">
          <v-col v-for="(s, n) in learnitems" :key="n" cols="6" class="px-2 pb-2">
            <v-card width="100%" :to="s.click" data-aos="zoom-in-up"
              class="secseven-card rounded-lg text-center pa-1 mx-auto">
              <img data-aos="zoom-in-down" :src="s.image" width="100%" class="px-6 pt-4" :alt="s.title">
              <p class="font-weight-bold title mb-0">{{ s.title }}</p>
              <p class="txt-444 font-weight-regular caption text-uppercase mb-2">{{ s.subtitle }}</p>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>

     <div class="sec-eight pb-md-10 rounded-xl">
        <!-- py-md-16 -->
        <!-- <v-card data-aos="fade-down" height="380px" class="seceight-bg rounded-xl pl-14 elevation-0 d-none d-md-block overflow-hiddens mb-16" color="#E5EBEC" width="100%"> -->
        <v-card data-aos="fade-down" height="240px" class="seceight-bg rounded-xl pl-14 elevation-0 d-none d-md-block overflow-hiddens" color="transparent" width="100%">
          <!-- <v-card width="100%" class="d-inline-flex elevation-0 pos-rlt" color="transparent">
            <v-card width="50%" class="elevation-0 py-16" color="transparent">
              <p class="c-secondary-font">
                Exceptional Performance. <br />
                Exceptional Results.
              </p>
              <p class="txt-444 font-weight-light fs-20 lh-24">
                Mynt is a sophisticated and <br />
                modern trading application.
              </p>
              <div class="rounded-0 d-inline-flex">
                <v-card target="_blank" rel="noopener noreferrer" height="45px" href="https://play.google.com/store/apps/details?id=com.zebu.mynt" color="#000" class="mr-2 rounded-lg elevation-0 hcardd">
                  <img src="@/assets/product/scr-mob1.svg" class="px-1 pt-1" alt="playstore-btn-img" />
                </v-card>
                <v-card target="_blank" rel="noopener noreferrer" height="45px" href="https://apps.apple.com/in/app/mynt-zebu/id1663709767" color="#000" class="mr-2 rounded-lg elevation-0 hcardd">
                  <img src="@/assets/product/scr-mob2.svg" class="px-1 pt-1" alt="playstore-btn-img" />
                </v-card>
                <v-card target="_" rel="noopener noreferrer" height="45px" href="https://zebuetrade.com/asset/static/Downloads/Apps/ZEBU_INV.exe" color="#000" class="rounded-lg elevation-0 hcardd">
                  <img src="@/assets/product/scr-mob3.svg" class="px-1 pt-1" alt="web-btn-img" />
                </v-card>
              </div>
            </v-card>
            <v-card width="60%" class="elevation-0 mr-4 overflow-hidden" color="transparent" data-aos="fade-up">
              <img src="@/assets/newimg.png" width="100%" alt="newimg" class="pa-0" />
            </v-card>
          </v-card> -->
        </v-card>
        <!-- <v-card data-aos="fade-down" class="seceight-bg rounded-0 pt-14 pb-10 mb-10 px-6 px-sm-8 elevation-0 d-md-none text-center" color="#E5EBEC" width="100%"> -->
        <v-card data-aos="fade-down" class="seceight-bg rounded-0 pb-10 mb-10 px-6 px-sm-8 elevation-0 d-md-none text-center" color="transparent" width="100%">
          <!-- <v-card width="100%" class="elevation-0" color="transparent">
            <v-card width="100%" class="elevation-0" color="transparent">
              <p class="c-secondary-font">Exceptional Performance. Exceptional Results.</p>
              <p class="txt-444 font-weight-light fs-20 lh-24">Mynt is a sophisticated and modern trading application.</p>
            </v-card>
            <v-card width="100%" class="elevation-0" color="transparent" data-aos="fade-up">
              <img src="@/assets/newimg.png" width="100%" alt="newimg" class="pa-2" />
            </v-card>
            <v-card target="_blank" rel="noopener noreferrer" height="45px" href="https://play.google.com/store/apps/details?id=com.zebu.mynt" color="#282828" class="rounded-lg elevation-0 mb-3">
              <img src="@/assets/product/scr-mob1.svg" class="px-1 pt-1" alt="playstore-btn-img" />
            </v-card>
            <v-card target="_blank" rel="noopener noreferrer" height="45px" href="https://apps.apple.com/in/app/mynt-zebu/id1663709767" color="#282828" class="rounded-lg elevation-0 mb-3">
              <img src="@/assets/product/scr-mob2.svg" class="px-1 pt-1" alt="playstore-btn-img" />
            </v-card>
            <v-card target="_" rel="noopener noreferrer" height="45px" href="https://zebuetrade.com/asset/static/Downloads/Apps/ZEBU_INV.exe" color="#282828" class="rounded-lg elevation-0">
              <img src="@/assets/product/scr-mob3.svg" class="px-1 pt-1" alt="web-btn-img" />
            </v-card>
          </v-card> -->
        </v-card>
      <div data-aos="fade-up" class="secineight-bg mb-8 mb-md-0">
        <v-card color="transparent" class="secineight-card pos-rlt mx-auto brd-r-32 elevation-0 d-none d-md-block"
          width="90%">
          <img src="@/assets/sec-eight-bg.webp" width="100%" alt="sec-eight-bg">

          <div class="seceight-ctn pos-abs">
            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">
              <v-row no-gutters class="pt-10">
                <v-col cols="9" class="pr-4">
                  <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()" :maxlength="10" :rules="numberis"
                    required hide-spin-buttons flat solo background-color="#595959"
                    class="menu-field-seceight elevation-0 mr-3 rounded-pill caption txt-field"
                    label="Enter mobile number to begin">
                    <template #prepend-inner>
                      <img src="@/assets/phone-icon white.svg" width="100%" class="mx-2" alt="phone-icon">
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
                    class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
                      up</span></v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
        <v-card color="#282828" class="secineight-card pos-rlt mx-auto brd-r-32 elevation-0 d-md-none pa-6" width="90%">
          <img src="@/assets/Msec-eight-bg.svg" width="100%" alt="sec-eight-bg">
          <!-- <v-img src="@/assets/Msec-eight-bg.svg" width="100%" alt="sec-eight-bg"></v-img> -->

          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">

            <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()" :maxlength="10" :rules="numberis" required
              hide-spin-buttons flat solo background-color="#595959"
              class="menu-field-seceight elevation-0 rounded-pill caption txt-field"
              label="Enter mobile number to begin">
              <template #prepend-inner>
                <img src="@/assets/phone-icon white.svg" width="100%" class="mx-2" alt="phone-icon">
              </template>
            </v-text-field>
            <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
              class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
                up</span></v-btn>
          </v-form>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { counts } from '../apiurl';

export default {
  data: () => ({
    investactive: [],
    inaid: 1,
    secthreeitems: [
      {
        id: 1,
        title: 'Stocks & ETFs',
        image: require('@/assets/products-sec/Stocks-ETFs.png'),
        tit: "Invest in stocks for longterm wealth",
        sub: "Own a part of leading companies, unlock potential dividends, and watch your investments grow steadily. Begin building your financial future today.",
        too: "",
        data: [
        { id: 0, image: require("@/assets/invest/inv-p-l1.png"), tit: "Potential for High Returns", sub: "Investing in stocks for the long term is a reliable strategy to build wealth and gain significant returns over time." },
      { id: 1, image: require("@/assets/invest/inv-p-l2.png"), tit: "Portfolio Diversification", sub: "Traditional investments often fall short in delivering ideal returns. Diversifying with equity investments through stocks and mutual funds can provide the opportunity for better-than-average returns" },
      { id: 2, image: require("@/assets/invest/inv-p-l3.png"), tit: "High Liquidity", sub: "Unlike bonds and fixed deposits, stocks and mutual funds provide superior liquidity with near-instant price discovery, ensuring your assets remain easily accessible." },
      { id: 3, image: require("@/assets/invest/inv-p-l4.png"), tit: "Dividend Yield", sub: "Passive income is a growing trend, and dividend investing offers a way to achieve it. By investing in reliable, dividend-paying companies, you can build a steady stream of income over time." },
        ]
      },
      {
        id: 2,
        title: 'Mutual funds',
        image: require('@/assets/products-sec/mutual-funds.png'),
        tit: "Mutual Funds for Financial Growth",
        sub: "Grow your wealth affordably with mutual funds. Simplify your journey through SIPs. Build a financially secure future with tax-efficient investments.",
        too: "",
        data: [
        { id: 0, image: require("@/assets/product/mf/l1.png"), tit: "Expert Management", sub: "Mutual funds are overseen by SEBI-certified professionals who actively work to deliver returns that exceed market benchmarks." },
      { id: 1, image: require("@/assets/product/mf/l2.png"), tit: "Tax Advantages", sub: "Mutual funds offer significant tax efficiency through indexation, helping you save an average of 3-4% on taxes compared to other investment options." },
      { id: 2, image: require("@/assets/product/mf/l3.png"), tit: "Systematic Investment Plans", sub: "A smart approach to investing, SIPs allow you to allocate capital effectively, aligning with your financial goals and risk preferences for steady growth." },
      { id: 3, image: require("@/assets/product/mf/l4.png"), tit: "Direct Investment - Save 2%", sub: "With Zebu’s direct mutual funds, you can avoid high expense ratios of regular funds, allowing your investments to grow and compound more efficiently." },
      { id: 4, image: require("@/assets/product/mf/l5.png"), tit: "Affordable Investments", sub: "Mutual funds offer affordability, allowing you to start investing with Zebu for as little as ₹500. Begin small and watch your wealth grow over time." },
        ]
      },
      {
        id: 3,
        title: 'IPOs',
        image: require('@/assets/products-sec/ipos.png'),
        tit: "Upcoming Company Listings Opportunities",
        sub: "Invest in newly listed companies to grow wealth and diversify your portfolio. Tap into exciting opportunities while participating in future markets.",
        too: "",
        data: [
        { id: 0, image: require("@/assets/product/ipos/l3.png"), tit: "Apply for IPOs in Just 3 Simple Steps!", sub: "Choose your preferred IPO, decide the number of shares to bid for, and make a seamless payment to complete your application effortlessly." },
      { id: 1, image: require("@/assets/product/ipos/l1.png"), tit: "Primary Market Allocation", sub: "Get early access to India's rising companies through IPOs. Invest in their growth journey and position yourself for potential long-term returns as they establish themselves in the market." },
      { id: 2, image: require("@/assets/product/ipos/l2.png"), tit: "Pay Easily Via UPI for IPOs", sub: "Enjoy a smooth and efficient payment process for IPO applications. Use UPI to reserve your bids instantly with just a few clicks." },
      { id: 3, image: require("@/assets/product/ipos/l4.png"), tit: "Track  IPO Investments in Real-Time", sub: "Stay updated with the progress of your IPO bids. Monitor allocation status and company performance directly on your dashboard for a complete and hassle-free investing experience." },

        ]
      },
      {
        id: 4,
        title: 'Bonds',
        image: require('@/assets/products-sec/bonds.png'),
        tit: "Prepare to Build Financial Security with Bonds",
        sub: "Government bonds, backed by the Indian government, offer consistent returns. Secure your portfolio with stability and peace of mind.",
        too: "",
        data: [
        { id: 0, image: require('@/assets/product/gb/l1.png'), tit: "GOI-Backed Bonds", sub: "All bonds offered by Zebu are fully backed by the Government of India and verified by regulatory authorities, ensuring unmatched security and reliability." },
      { id: 1, image: require('@/assets/product/gb/l2.png'), tit: "Tax-Efficient Returns", sub: "Enjoy tax-friendly benefits and inflation indexation with Government bonds, making them a smart choice for stable, long-term growth." },
      { id: 2, image: require('@/assets/product/gb/l3.png'), tit: "Stable and Reliable Income", sub: "Government bonds provide a steady income stream, offering predictable returns for investors seeking financial stability." },
      { id: 3, image: require('@/assets/product/ipos/l4.png'), tit: "Perfect for Long-Term Goals", sub: "Ideal for long-term investors, these bonds combine safety, tax advantages, and consistent returns to help you achieve your financial aspirations." },
        ]
      },
      {
        id: 5,
        title: 'Gold bonds',
        image: require('@/assets/products-sec/sgb.png'),
        tit: "Fortify Portfolio with Sovereign Gold Bonds",
        sub: "Backed by the Government of India, enjoy the stability of gold and tax benefits. Invest confidently with a hassle-free, rewarding alternative to physical gold.",
        too: "",
        data: [
        { id: 0, image: require('@/assets/product/sgb/l1.png'), tit: "Benefit from Gold Rise", sub: "Invest in Sovereign Gold Bonds (SGBs) and gain from the growth in gold prices without the challenges of holding physical gold." },
      { id: 1, image: require('@/assets/product/sgb/l2.png'), tit: "Earn 2.5% Additional Interest", sub: "Enjoy an extra 2.5% annual interest in addition to the returns from gold price appreciation, boosting your overall investment value." },
      { id: 2, image: require('@/assets/product/sgb/l3.png'), tit: "Safe and Easy Investment", sub: "Backed by the Government of India, SGBs offer a safe, hassle-free alternative to physical gold investments." },
      { id: 3, image: require('@/assets/product/sgb/l4.png'), tit: "Tax Benefits and Flexibility", sub: "Benefit from tax advantages and the flexibility of trading bonds on exchanges, making SGBs a smart choice for modern investors." },
        ]
      },
      {
        id: 6,
        title: 'Insurance',
        image: require('@/assets/product/lpins/insurance.svg'),
        tit: "Insurance made easy",
        sub: "Experience expert guidance for finding your ideal plan. Get clear answers and a stress-free process, all in one place. Start your peace of mind journey today!",
        too: "",
        data: [
        {
                id: 0,
                image: require("@/assets/product/lpins/lifein.svg"),
                tit: "Life Insurance",
                sub: "Secure the future of your loved ones with life insurance plans designed to provide financial security tailored to your unique needs and life stage. Protect your family today and safeguard them against life's uncertainties.",
            },
            {
                id: 1,
                image: require("@/assets/product/lpins/genin.svg"),
                tit: "General Insurance",
                sub: "Whether it’s your car, two-wheeler, home, or business, our comprehensive general insurance policies are here to protect your most valuable assets. Enjoy peace of mind and safeguard your belongings with reliable coverage. We’ve got you covered, no matter where life takes you.",
            },
            {
                id: 2,
                image: require("@/assets/product/lpins/healin.svg"),
                tit: "Health Insurance",
                sub: "Stay financially prepared for medical emergencies and accidents with our comprehensive health insurance plans. Our policies ensure you're covered when it matters most, so you can focus on your well-being while we handle the rest.",
            },
        ]
      },
      {
        id: 7,
        title: 'PMS (Distributor)',
        image: require('@/assets/product/lpaif/homepagepms.svg'),
        tit: "Empower Your Portfolio with Zebu",
        sub: "PMS is a personalized investment solution designed for High Net Worth Individuals (HNIs) and Ultra HNIs, focusing on strategic allocation in stocks, bonds, mutual funds, and other securities. With Zebu’s seasoned fund managers, your portfolio aligns with your financial goals and risk tolerance for optimal growth.",
        too: "",
        data: [
        { id: 0, image: require("@/assets/product/lppms/spl5.png"), tit: "Focused Portfolio", sub: "Experience focused portfolio management with Zebu’s PMS. We carefully select 15-25 high-growth companies to maximize your returns, ensuring a strategy designed for superior financial outcomes." },
            { id: 1, image: require("@/assets/product/lppms/spl3.png"), tit: "Direct Ownership", sub: "Enjoy direct ownership with Zebu’s PMS. Your investments are fully transparent and securely held in your Demat account for complete control and peace of mind." },
            { id: 2, image: require("@/assets/product/lppms/spl1.png"), tit: "Comprehensive Monitoring", sub: "Zebu’s PMS offers comprehensive monitoring with risk-managed strategies and performance-enhanced portfolios, ensuring steady growth and financial security." },
            { id: 3, image: require("@/assets/product/lppms/spl4.png"), tit: "Personalized Attention" ,sub: "Experience personalized attention with Zebu’s PMS. Enjoy support from dedicated relationship managers and direct access to your fund manager for tailored investment guidance." },
            { id: 4, image: require("@/assets/product/lppms/spl2.png"), tit: "Paperless Onboarding", sub: "Zebu’s PMS offers paperless onboarding for a seamless and hassle-free investment setup, making it easy to start your financial journey." },
        ]
      },
      {
        id: 8,
        title: 'AIF (Distributor)',
        image: require('@/assets/product/lpaif/mainimg.png'),
        tit: "Your Path to Exceptional Investment Growth",
        sub: "At Zebu, our expert fund managers design strategic, data-driven investment plans that align with your risk tolerance and financial objectives. With a focus on adaptability and performance, we help you achieve sustainable growth and maximize returns in today’s dynamic investment landscape.",
        too: "",
        data: [
        { id: 0, image: require("@/assets/product/lpaif/tradetool.png"), tit: "Precision-Driven Investments", sub: "Experience strategic portfolio management with Zebu’s AIF. We curate a selection of high-potential opportunities across alternative assets, ensuring a focused approach to deliver superior returns and sustainable financial growth." },
            { id: 1, image: require("@/assets/product/lpaif/data2.png"), tit: "Transparent & Secure Investments", sub: "With Zebu’s AIF, enjoy full transparency and confidence. Your investments are securely managed, providing clarity, control, and peace of mind as you navigate exclusive alternative opportunities." },
            { id: 2, image: require("@/assets/product/lpaif/data3.png"), tit: "Holistic Portfolio Oversight", sub: "Zebu’s AIF provides thorough monitoring with risk-optimized strategies and performance-driven portfolios, ensuring consistent growth and long-term financial stability." },
            { id: 3, image: require("@/assets/product/lpaif/data4.png"), tit: "Tailored Investment Guidance", sub: "With Zebu’s AIF, receive personalized support from dedicated relationship managers and direct access to expert fund managers, ensuring customized strategies aligned with your financial goals." },
            { id: 4, image: require("@/assets/product/lpaif/data5.png"), tit: "Seamless Digital Onboarding", sub: "Zebu’s AIF ensures a smooth, paperless onboarding process, allowing you to kickstart your investment journey effortlessly and without hassle." },
        ]
      }
    ],
    secfouritems: [
      { text: 'Option tools', link: '' },
      { text: 'Powerful charting', link: '' },
      { text: 'Collections', link: '' },
      { text: 'Wishlist', link: '' },
      { text: 'Margin', link: '' },
      { text: 'Theme Investing', link: '' },
      { text: 'RIsk', link: '' },
      { text: 'Diversified', link: '' },
      { text: 'Weighted', link: '' },
    ],
    learnitems: [
      // {
      //   title: 'Webinars',
      //   image: require('@/assets/learn-sec/image 29.png'),
      //   subtitle: '14 Webinars',
      //   click: ''
      // },
      {
        title: 'Blogs',
        image: require('@/assets/learn-sec/image 34.png'),
        subtitle: '0 Blogs',
        click: '/blogs'
      },
      {
        title: 'Videos',
        image: require('@/assets/learn-sec/image 28.png'),
        subtitle: '0 Videos',
        click: '/videos'
      },
      // {
      //   title: 'Digest',
      //   image: require('@/assets/learn-sec/image 30.png'),
      //   subtitle: '14 Digests',
      //   click: ''
      // },
      {
        title: 'News',
        image: require('@/assets/learn-sec/image 31.png'),
        subtitle: '0 today',
        click: '/news'
      },
      {
        title: 'Calculator',
        image: require('@/assets/learn-sec/image 32.png'),
        subtitle: '21 Calculators',
        click: '/calculator'
      },
    ],
    valid: true,
    mobile: '',
    numberis: [
      (v) => !!v || "Your mobile number is required",
      (v) =>
        /^[6-9][0-9]{9}$/.test(v) || "mobile number must be 10 Digit Number",
    ],
  }),
  mounted() {
    this.investactive = this.secthreeitems[0];
    this.learnitems[0].subtitle = counts.blogs_count ? `${counts.blogs_count} Blogs` : '0'
    this.learnitems[1].subtitle = counts.youtube_count ? `${counts.youtube_count} Videos` : '0'
    this.learnitems[2].subtitle = counts.today_news ? `${counts.today_news} today` : '0'
  },

  methods: {
    NumberValid($event) {
      if (($event.keyCode >= 48 && $event.keyCode <= 57) || ($event.keyCode >= 96 && $event.keyCode <= 105)) {
        // 0-9 only
      } else {
        $event.preventDefault();
      }
    },
    setinvestItem(n) {
      this.investactive = [];
      if (n.id == 1) {
        this.inaid = 1;
      } else if (n.id == 2) {
        this.inaid = 2;
      } else if (n.id == 3) {
        this.inaid = 3;
      } else if (n.id == 4) {
        this.inaid = 4;
      } else if (n.id == 5) {
        this.inaid = 5;
      }
      else if (n.id == 6) {
        this.inaid = 6;
      }
      else if (n.id == 7) {
        this.inaid = 7;
      }
      else if (n.id == 8) {
        this.inaid = 8;
      }
      this.investactive = n;

    },
    getCall() {
      if (this.$refs.form.validate() == true) {
        window.open(
          `https://oa.mynt.in/?ref=zws&mobile=${this.mobile}`
        );
        this.$refs.form.reset();

      }

    }
  },
  components: {
    // HelloWorld
  }
}
</script>
