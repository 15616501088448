import Vue from 'vue'
import VueRouter from 'vue-router'
// import getParams from './getParams';
import eventBus from '../eventBus';


import LayoutPage from '../components/LayoutPage.vue'

import HomeView from '../views/HomeView.vue'
import InvestView from '../views/InvestView.vue'
import TradeView from '../views/TradeView.vue'
// import PriceView from '../views/PriceView.vue'
import ProductView from '../views/ProductView.vue'
import AboutUs from '../views/AboutUs.vue'
import LifeView from '../views/LifeView.vue'
import SupportView from '../views/SupportView.vue'
import NewsView from '../views/NewsView.vue'
import ContactUs from '../views/ContactUs.vue'
import CareerView from '../views/CareerView.vue'
import ReferView from '../views/ReferView.vue'

import PrivacyPolicyView from '../views/Compliance/PrivacyPolicyView.vue'
import OnlinePayment from '../views/Compliance/OnlinePayment.vue'

import TermsConditions from '../views/Compliance/TermsConditions.vue'
import DownloadPage from '../views/Compliance/DownloadPage.vue'
import PledgeStock from '../views/Compliance/PledgeStock.vue'
import CsrPage from '../views/Compliance/CsrPage.vue'
import BankDetails from '../views/Compliance/BankDetails.vue'
import DisclaimerPage from '../views/Compliance/DisclaimerPage.vue'
import BlogPolicy from '../views/Compliance/BlogPolicy.vue'
import DisposalComplaints from '../views/Compliance/DisposalComplaints.vue'
import AntiMoney from '../views/Compliance/AntiMoney.vue'
import InvestorCharter from '../views/Compliance/InvestorCharter.vue'
import HoldingPage from '../views/Compliance/HoldingPage.vue'
import NoHoldingPage from '../views/Compliance/NoHoldingPage.vue'
import InactiveAccount from '../views/Compliance/InactiveAccount.vue'
import RMSPolicy from '../views/Compliance/RMSPolicy.vue'
import ReportsPage from '../views/Compliance/ReportsPage.vue'
import DepositoryInvestor from '../views/Compliance/DepositoryInvestor.vue'
import InvestorDps from '../views/Compliance/InvestorDps.vue'

import StocksETFs from '../views/Products/StocksETFs.vue'
import IposView from '../views/Products/IposView.vue'
import IposLPView from '../views/Products/IposLPView.vue'
import SovereigngoldBond from '../views/Products/SovereigngoldBond.vue'
import CommoDities from '../views/Products/CommoDities.vue'
import CurrenCies from '../views/Products/CurrenCies.vue'
import FuturesOptions from '../views/Products/FuturesOptions.vue'
import GovernmentBonds from '../views/Products/GovernmentBonds.vue'
import MutualFunds from '../views/Products/MutualFunds.vue'
import AppsSrc from '../views/Products/AppsSrc.vue'
import AppPromo from '../views/Products/AppPromo.vue'

import HomeVideos from '../views/videos/HomeVideos.vue'
import PlaylistAll from '../views/videos/PlaylistAll.vue'
import PlayList from '../views/videos/PlayList.vue'

import VideosAll from '../views/videos/VideosAll.vue'
import ShortsAll from '../views/videos/ShortsAll.vue'
import VideoLayout from '../views/videos/VideoLayout.vue'

import CalculatorLayout from '../views/Calculators/CalculatorLayout.vue'
import CalculatorHome from '../views/Calculators/CalculatorHome.vue'
import NpsCalculator from '../views/Calculators/NpsCalculator.vue'
import BrokerageCalculator from '../views/Calculators/BrokerageCalculator.vue'
import EmiCalculator from '../views/Calculators/EmiCalculator.vue'
import EpfCalculator from '../views/Calculators/EpfCalculator.vue'
import FlatreducingCalculator from '../views/Calculators/FlatreducingCalculator.vue'
import SpanCalculator from '../views/Calculators/SpanCalculator.vue'
import CagrCalculator from '../views/Calculators/CagrCalculator.vue'
import CarloanCalculator from '../views/Calculators/CarloanCalculator.vue'
import CompoundinterestCalculator from '../views/Calculators/CompoundinterestCalculator.vue'
import FdCalculator from '../views/Calculators/FdCalculator.vue'
import GratuityCalculator from '../views/Calculators/GratuityCalculator.vue'
import GstCalculator from '../views/Calculators/GstCalculator.vue'
import HomeloanCalculator from '../views/Calculators/HomeloanCalculator.vue'
import HraCalculator from '../views/Calculators/HraCalculator.vue'
import LumpsumCalculator from '../views/Calculators/LumpsumCalculator.vue'
import MutualfundCalculator from '../views/Calculators/MutualfundCalculator.vue'
import PpfCalculator from '../views/Calculators/PpfCalculator.vue'
import RdCalculator from '../views/Calculators/RdCalculator.vue'
import SimpleinterestCalculator from '../views/Calculators/SimpleinterestCalculator.vue'
import SipCalculator from '../views/Calculators/SipCalculator.vue'
import SsyCalculator from '../views/Calculators/SsyCalculator.vue'
// import GoalPlanCalculator from '../views/Calculators/GoalPlanCalculator.vue'

import HomeBlog from '../views/Blogs/HomeBlog.vue'
import BlogCategory from '../views/Blogs/BlogCategory.vue'
import SinglBlog from '../views/Blogs/SinglBlog.vue'

import SingleStocks from '../views/stocks/SingleStocks.vue'

import ScreenView from '../views/Screens/ScreenView.vue'
import InsuranceLP from '../views/Products/InsuranceLP.vue'
import PartnerLP from '../views/Products/PartnerLP.vue'
import IpoSinglepage from '../views/Products/IpoSinglepage.vue'
import IpoSinglePadeNL from '../views/Products/IpoSinglePadeNL.vue'
import PmsLandingpage from '../views/Products/PmsLandingpage.vue'
import AifLP from '../views/Products/AifLP.vue'


import NotFoundPage from '../views/NotFoundPage.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        component: NotFoundPage,
    },
    {
        path: '/',
        redirect: 'Homeview',
        name: 'layout',
        component: LayoutPage,
        children: [
            {
                path: '/',
                name: 'Homeview',
                component: HomeView,
                meta: {
                    title: "ZEBU: India's favourite personal online broker.",
                    description: "ZEBU is India's favorite Online Stock Broking & Trading Platform with low-cost brokerage. We also deal with NSE/BSE Indices, Trade Stocks, Futures & Options, IPOs, ETFs, Mutual Funds, Commodity, & Currency, U.S stocks.",
                    keywords: "discount broker, discount brokerage, lowest brokerage commissions, lowest brokerage fees, indian discount brokerage, indian discount broker, cheap brokerage, discount brokerage bangalore, fixed brokerage bangalore, cheap trading, cheap commodity trading, trading terminal, futures trading, stock broker, fixed stock brokerage, cheapest brokerage, cheapest brokerage in india, online trading, online brokerage, cheap demat account, bangalore broker, commodities trading"
                }
            },
            {
                path: '/invest',
                name: 'Invest',
                component: InvestView
            },
            {
                path: '/trade',
                name: 'trade',
                component: TradeView
            },
            // {
            //     path: '/price',
            //     name: 'price',
            //     component: PriceView
            // },
            {
                path: '/product',
                name: 'product',
                component: ProductView
            },
            {
                path: '/about',
                name: 'about us',
                component: AboutUs,
                meta: {
                    title: "About ZEBU: Company's History and defining Milestones",
                    description: "Trusted by over a million traders, ZEBU is one of the fastest-growing Share Brokers in India. We are also a member of the NSE, BSE, MCX, MCX-SX, and CDSL.",
                    keywords: "Zebu was founded with a shared understanding of what the Indian trader lacked to become profitable."
                }
            },
            {
                path: '/lifeatzebu',
                name: 'zebu life',
                component: LifeView
            },
            {
                path: '/support',
                name: 'Support',
                component: SupportView,
                meta: {
                    title: "Support - Everything you need to know about | ZEBU",
                    description: " Everything you need to know about Zebu At Zebu, we believe in a flat hierarchy  that is conducive for maximum growth for everyone working for us. If you are motivated by challenges and  enjoy an environment that is dynamic, challenging and rewarding, we will always have a place for you.",
                    keywords: "steps involved to open a Trading Account As per the regulatory requirement, if a person is trading futures and options, then, he or she has to produce income proof. Futures and options are leveraged derivative products and not an appropriate avenue for someone with limited resources/limited investment and low-risk tolerance"
                }
            },
            {
                path: '/contactus',
                name: 'Contact us',
                component: ContactUs,
                meta: {
                    title: "Contact us - Customer Enquries and Questions | ZEBU",
                    description: "Trusted by over a million traders, ZEBU is one of the fastest-growing Share Brokers in India. We are also a member of the NSE, BSE, MCX, MCX-SX, and CDSL.",
                    keywords: "Stuck somewhere, you're just a click away from help @ Zebu. Type your question for answers."
                }
            },
            {
                path: '/career',
                name: 'career',
                component: CareerView
            },
            {
                path: '/referral',
                name: 'refer',
                component: ReferView
            },
            {
                path: '/privacy',
                name: 'privacy policy',
                component: PrivacyPolicyView
            },
            {
                path: '/onlinepayment',
                name: 'online payment',
                component: OnlinePayment
            },
            {
                path: '/termsandconditions',
                name: 'terms and conditions',
                component: TermsConditions
            },
            {
                path: '/downloads',
                name: 'Download',
                component: DownloadPage
            },
            {
                path: '/pledge',
                name: 'pledge',
                component: PledgeStock
            },
            {
                path: '/csr',
                name: 'CSR',
                component: CsrPage
            },
            {
                path: '/bank',
                name: 'bank details',
                component: BankDetails
            },
            {
                path: '/disclaimer',
                name: 'disclaimer',
                component: DisclaimerPage
            },
            {
                path: '/blogpolicy',
                name: 'blog policy',
                component: BlogPolicy
            },
            {
                path: '/disposalcomplaints',
                name: 'disposal complaints',
                component: DisposalComplaints
            },
            {
                path: '/antimoneylaunderingpolicy',
                name: 'anti money laundering policy',
                component: AntiMoney
            },
            {
                path: '/investorcharter',
                name: 'investor Charter',
                component: InvestorCharter
            },
            {
                path: '/holding',
                name: 'holding position',
                component: HoldingPage
            },
            {
                path: '/noholding',
                name: 'no holding position',
                component: NoHoldingPage
            },
            {
                path: '/inactiveacct',
                name: 'inactive account',
                component: InactiveAccount
            },
            {
                path: '/rmspolicy',
                name: 'RMS policy',
                component: RMSPolicy
            },
            {
                path: '/reports',
                name: 'reports',
                component: ReportsPage
            },
            {
                path: '/depositinvestorchart',
                name: 'depository investor charter ',
                component: DepositoryInvestor
            },
            {
                path: '/investordps',
                name: 'Investor dps ',
                component: InvestorDps
            },
            {
                path: '/stocks',
                name: 'Stocks & ETFs',
                component: StocksETFs
            },
            {
                path: '/ipos',
                name: 'ipos',
                component: IposView
            },
            {
                path: '/ipo',
                name: 'landing page ipos',
                component: IposLPView
            },
            {
                path: '/pms',
                name: 'pms landing page',
                component: PmsLandingpage
            },
            {
                path: '/aif',
                name: 'aif landing page',
                component: AifLP
            },


            {
                path: '/insurance',
                name: 'landing page isurance',
                component: InsuranceLP
            },
            {
                path: '/partner',
                name: 'landing page partner',
                component: PartnerLP
            },
            {
                path: '/sgb',
                name: 'Sovereign gold bonds',
                component: SovereigngoldBond
            },
            {
                path: '/commodities',
                name: 'Commodities',
                component: CommoDities
            },
            {
                path: '/currencies',
                name: 'Currencies',
                component: CurrenCies
            },
            {
                path: '/futuresoptions',
                name: 'Futures options',
                component: FuturesOptions
            },
            {
                path: '/governmentbonds',
                name: 'Government bonds',
                component: GovernmentBonds
            },
            {
                path: '/mutualfunds',
                name: 'Mutual funds',
                component: MutualFunds
            },

            {
                path: '/news',
                name: 'news',
                component: NewsView
            },
            {
                path: '/blogs',
                name: 'home blog',
                component: HomeBlog
            },
            {
                path: '/blogcategory',
                name: 'blog category',
                component: BlogCategory
            },
            {
                path: '/blog/:title',
                name: 'single blog',
                component: SinglBlog
            },
            {
                path: '/videos',
                redirect: 'VideoLayout',
                name: 'layout video',
                component: VideoLayout,
                children: [
                    {
                        path: '/videos',
                        name: 'home videos',
                        component: HomeVideos
                    },
                    {
                        path: '/video',
                        name: 'videos all',
                        component: VideosAll
                    },
                    {
                        path: '/shorts',
                        name: 'videos shorts',
                        component: ShortsAll
                    },
                    {
                        path: '/playlists',
                        name: 'videos playlists',
                        component: PlaylistAll
                    },
                    {
                        path: '/playlist',
                        name: 'playlist',
                        component: PlayList
                    },
                ]
            },
            {
                path: '/calculator',
                redirect: 'CalculatorLayout',
                name: 'layout calculator',
                component: CalculatorLayout,
                children: [
                    {
                        path: '/calculator',
                        name: 'calculator',
                        component: CalculatorHome
                    },
                    {
                        path: '/calculator/nps',
                        name: 'nps calculator',
                        component: NpsCalculator
                    },
                    {
                        path: '/calculator/span',
                        name: 'span calculator',
                        component: SpanCalculator
                    },
                    {
                        path: '/calculator/cagr',
                        name: 'Cagr calculator',
                        component: CagrCalculator
                    },
                    {
                        path: '/calculator/carloan',
                        name: 'car loan calculator',
                        component: CarloanCalculator
                    },
                    {
                        path: '/calculator/brokerage',
                        name: 'brokerage calculator',
                        component: BrokerageCalculator
                    },
                    {
                        path: '/calculator/compoundinterest',
                        name: 'compound interest calculator',
                        component: CompoundinterestCalculator
                    },

                    {
                        path: '/calculator/fixeddeposit',
                        name: 'fixed deposit calculator',
                        component: FdCalculator
                    },
                    {
                        path: '/calculator/emi',
                        name: 'Emi calculator',
                        component: EmiCalculator
                    },
                    {
                        path: '/calculator/epf',
                        name: 'Epf calculator',
                        component: EpfCalculator
                    },
                    {
                        path: '/calculator/flatvsreduce',
                        name: 'Flat & reducing intrest calculator',
                        component: FlatreducingCalculator
                    },
                    {
                        path: '/calculator/gratuity',
                        name: 'Gratuity calculator',
                        component: GratuityCalculator
                    },
                    {
                        path: '/calculator/gst',
                        name: 'Gst calculator',
                        component: GstCalculator
                    },
                    {
                        path: '/calculator/homeloan',
                        name: 'Home loan calculator',
                        component: HomeloanCalculator
                    },
                    {
                        path: '/calculator/hra',
                        name: 'HRA calculator',
                        component: HraCalculator
                    },
                    {
                        path: '/calculator/lumpsum',
                        name: 'Lumpsum intrest calculator',
                        component: LumpsumCalculator
                    },
                    {
                        path: '/calculator/mutualfund',
                        name: 'Mutual fund calculator',
                        component: MutualfundCalculator
                    },
                    {
                        path: '/calculator/ppf',
                        name: 'PPF calculator',
                        component: PpfCalculator
                    },
                    {
                        path: '/calculator/rd',
                        name: 'RD calculator',
                        component: RdCalculator
                    },
                    {
                        path: '/calculator/simpleinterest',
                        name: 'Simple interest calculator',
                        component: SimpleinterestCalculator
                    },
                    {
                        path: '/calculator/sip',
                        name: 'SIP calculator',
                        component: SipCalculator
                    },
                    {
                        path: '/calculator/ssy',
                        name: 'SSY calculator',
                        component: SsyCalculator
                    },
                    // {
                    //     path: '/calculator/goalplanner',
                    //     name: 'Goal planner calculator',
                    //     component: GoalPlanCalculator
                    // },
                ]
            },
        ]
    },
    {
        path: '/stocks/single',
        alias: '/stocks/*',
        name: 'single stocks',
        component: SingleStocks,
    },
    {
        path: '/ipos/singlepagenl',
        name: 'ipo sinplepage np',
        component: IpoSinglePadeNL
    },
    {
        path: '/ipos/singlepage',
        name: 'ipo singlepage',
        component: IpoSinglepage
    },

    {
        path: '/apps',
        name: 'apps downloads',
        component: AppsSrc,
    },
    {
        path: '/apppromo',
        name: 'apps promo',
        component: AppPromo,
    },

    {
        path: '/span',
        name: 'screen view',
        component: ScreenView,
        children: [
            {
                path: '/span',
                name: 'span calculator',
                component: SpanCalculator
            },
        ]
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        window.scrollTo(0, 0);
        console.log('yes');
        const currentPath = window.location.pathname;
        eventBus.$emit("snack-event", currentPath);
    }
})

export default router


// export function fetchMyntAPI() {

//     const currentPath = window.location.pathname;
//     console.log("Current path:", currentPath);


//     const url = new URL(window.location.href);
//     const params = new URLSearchParams(url.search);
//     console.log("Query parameters:", params);
//     eventBus.$emit("snack-event", params);

// }
















