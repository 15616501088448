<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-2 font-weight-bold secondary-infont fs-32 lh-32">Mutual fund calculator</p>
                    <p class="title mb-1">Calculate the potential returns of your mutual fund investments over a chosen timeframe.
          </p>
          <v-divider class="title mb-7"></v-divider>
                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Investment amount</p>
                            <v-text-field type="number" @keyup="keyUpchartupdation()" hide-spin-buttons dense
                                v-model="Monthlyinvestment" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>

                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Interest Rate (p.a.)</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="%" v-model="interestrate"
                                        hide-spin-buttons min="1" max="20" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="interestrate" ticks="always" tick-size="6"
                                        min="1" max="20" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>

                            <v-row class="my-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Tenure period</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="Yr" v-model="tenureperiod"
                                        hide-spin-buttons min="1" max="50" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="tenureperiod" ticks="always" tick-size="6"
                                        min="1" max="50" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="mb-0 title font-weight-regular">Estimation</p>
                            <v-row>
                                <v-col cols="12" md="7" class="py-4 px-8 px-md-4 mt-6">
                                    <v-card class="elevation-0 rounded-circle pb-2 px-1 brd-c-4" outlined>
                                        <canvas id="myChart"> </canvas>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-list-item class="pa-0 mb-8 mt-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#015FEC"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Investment amount
                                            </v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emireport ?
                                                emireport : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#1D1D1D"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle
                                                class="mb-2 fs-18 font-weight-regular">Est.intrest</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiammount ?
                                                emiammount : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#6EB94B"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiinterest ?
                                                emiinterest : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="px-6 px-sm-8 px-md-10 py-6">
                    <p class="mb-0 font-weight-bold title">
                        What is the formula to calculate mutual funds returns?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        The formula for a Mutual fund calculator is M = P ([1 + i]n - 1 / I (1 + I))
                        <br><br>
                        M is the amount you get at maturity<br>
                        P is the amount you invest regularly<br>
                        n is the number of payments<br>
                        I is the rate of interest<br>
                    </p>
                    <p class="mb-0 font-weight-bold title">What is Mutual Fund Calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        A mutual fund calculator is a useful financial tool that helps investors figure out how much money
                        they will make by putting money into mutual funds. In general, there are two ways to put money into
                        mutual funds: lumpsum or every month.
                        <br><br>
                        This calculator will help you identify the returns of both types of investments. Simply input the
                        information and get accurate returns on your investment.
                    </p>
                    <p class="mb-0 font-weight-bold title">Advantages of Mutual fund investment?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        Here are some of the good things about mutual funds.
                        <br><br>
                        Liquidity<br>
                        A good thing about investing in a Mutual Fund is that the investor can get their money back at any
                        time.
                        <br><br>
                        Diversification<br>
                        Diversification lowers the risk of building a portfolio, which lowers an investor's risk even more.
                        Mutual funds are made up of many different securities, so investors' interests are protected even if
                        one of the securities goes down in value.
                        <br><br>
                        Expert Management<br>
                        A person who has never invested before might not know much about how and where to invest. Mutual
                        funds are run and managed by experts. The experts pool the money from the investors and put it in
                        different securities, which makes money for the investors.
                        <br><br>
                        The expert keeps an eye on when it's time to leave and enter and takes care of any problems that
                        come up. The only thing you have to do is invest, and you can be sure that the rest will be taken
                        care of by experts in this field.
                        <br><br>
                        Having the freedom to invest in smaller amounts, mutual funds have many benefits, and an important
                        one is that they are flexible. To put money into a Mutual Fund, investors don't have to
                        put in a lot of money. Investments can be made based on how much cash is coming in.
                        <br><br>
                        You can sign up for a Systematic Investment Plan if you get paid every month (SIP). With SIP, a
                        fixed amount is invested either once a month or once every three months, depending on your budget
                        and how convenient it is.
                        <br><br>
                        Mutual funds are easy to buy and sell.<br>
                        Mutual funds are easy to buy and invest in, and you can do so from anywhere in the world.
                        <br><br>
                        Plans for all kinds of financial objectives<br>
                        A good thing about the Mutual Fund is that you can put in as little as Rs. 500. And the maximum
                        amount an investor can put in is up to them.
                        <br><br>
                        Before putting money into Mutual Funds, the only thing people should think about is their income,
                        expenses, willingness to take risks, and investment goals.
                        <br><br>
                        Safety<br>
                        With the help of SEBI rules, all of a Mutual Fund's products are now labelled. This means that there
                        will be a colour code for each Mutual Fund scheme. This helps an investor figure out how risky his
                        investment is. This makes the whole investment process clear and safe.
                        <br><br>
                        This color-coding system uses three different colours to show different levels of risk-
                        <br><br>
                        Blue means the risk is low.<br>
                        Yellow means there is some risk, and brown means there is a lot of risk.
                        <br><br>
                        Investors are also free to check the qualifications of the fund manager, as well as his years of
                        experience, the amount of money under management (AUM), and the solvency of the fund house.
                        <br><br>
                        Cost less<br>
                        In a Mutual Fund, money from many different investors is pooled together and then used to buy
                        securities. But these funds are invested in assets, which helps save on transaction and other costs
                        compared to a single transaction. Investors pay less to put money into Mutual Funds because of the
                        savings.
                        <br><br>
                        Also, the Asset Management Services fee cost is lowered, and the same amount is split among all of
                        the fund's investors.
                        <br><br>
                        A good way to save on taxes<br>
                        Mutual funds offer the good ways to save money on taxes. Section 80C of the Income Tax Act gives
                        ELSS Mutual Funds a tax break of Rs. 1.5 lakh per year.
                    </p>
                    <p class="mb-0 font-weight-bold title">What is a Mutual Fund calculator how does work?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        While mutual fund returns may not be fixed every year, we can calculate the returns to an
                        approximation. You can use Zebu's free mutual fund return calculator to find out how much you can
                        expect to get back after investing in mutual funds over a set period of time.
                        <br><br>
                        Type in the amount you've invested, the rate of return you expect, and the number of years you've
                        been investing. Your investment's value at the end of the time period you choose will be shown
                        within second
                    </p>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
    data() {
        return {
            Monthlyinvestment: 10000,
            interestrate: 10,
            tenureperiod: 2,

            emireport: 0,
            emiammount: 0,
            emiinterest: 0,

            doughnuts: [],
        }
    },

    mounted() {
        this.compCalculator();
        this.setChart();
    },

    methods: {
        keyUpchartupdation() {
            setTimeout(() => {
                this.compCalculator();
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        chartupdation() {
            this.compCalculator();
            setTimeout(() => {
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        compCalculator() {
            var Principleammount = this.Monthlyinvestment;
            var InterestRate = this.interestrate / (100);
            var TenurePeriodYear = this.tenureperiod;
            var first = Math.pow(1 + InterestRate, TenurePeriodYear)
            var second = Math.round(Principleammount * first)
            var third = Math.round(second - Principleammount);
            this.emireport = (Principleammount.toLocaleString());
            this.emiammount = (third.toLocaleString());
            this.emiinterest = (second.toLocaleString());
            this.doughnuts = [Principleammount, third];
        },
        setChart() {
            const setdoughnut = document.getElementById("myChart").getContext("2d");
            this.doughnut = new Chart(setdoughnut, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: this.doughnuts,
                        backgroundColor: ['#015FEC', '#1D1D1D'],
                        radius: "100%",
                        borderColor: ['#015FEC', '#1D1D1D'],
                        borderRadius: 0,
                        borderWidth: 0,
                        hoverOffset: 4,
                        borderAlign: 'center'
                    }]
                },

                options: {
                    cutout: "70%",
                    // rotation: -90,
                    // circumference: 180,
                    events: [],
                    title: {
                        // display: false,
                    },
                    legend: {
                        // display: false,
                    },
                    layout: {
                        padding: {
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }
                    },
                }
            });
        },
    }


}
</script>