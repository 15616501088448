<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-2 font-weight-bold secondary-infont fs-32 lh-32">Flat & reducing intrest calculator</p>
                    <p class="title mb-1">Compare flat and reducing interest rates to make informed loan decisions easily.
          </p>
          <v-divider class="title mb-7"></v-divider>
                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Loan amount</p>
                            <v-text-field type="number" @keyup="atChange()" hide-spin-buttons dense
                                v-model="Principleammount" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>

                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Interest Rate (p.a.)</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense @keyup="atChange()"
                                        type="number" suffix="%" v-model="InterestRate" hide-spin-buttons min="1" max="50"
                                        step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="atChange()" v-model="InterestRate" ticks="always" tick-size="6"
                                        min="1" max="20" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>

                            <v-row class="my-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Tenure period</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense @keyup="atChange()"
                                        type="number" suffix="Yr" v-model="TenurePeriodYear" hide-spin-buttons min="1"
                                        max="50" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="atChange()" v-model="TenurePeriodYear" ticks="always" tick-size="6"
                                        min="1" max="50" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-row>
                                <v-col cols="6">
                                    <p class="title font-weight-regular">Flat Rate</p>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#015FEC"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Monthly EMI
                                            </v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emireport ?
                                                emireport : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#1D1D1D"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                intrest</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiammount ?
                                                emiammount : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#6EB94B"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiinterest ?
                                                emiinterest : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="6">
                                    <p class="title font-weight-regular">Reducing rate</p>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Monthly EMI
                                            </v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emireportt ?
                                                emireportt : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                intrest</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiammountt ?
                                                emiammountt : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiinterestt ?
                                                emiinterestt : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="px-6 px-sm-8 px-md-10 py-6">
                    <p class="mb-0 font-weight-bold title">What are flat and reducing interest rates?</p>
                    <p class="mb-6 font-weight-light txt-444">Interest is added to the principal to pay the lender.
                        Different banks figure out loan interest rates in a similar way. The interest rate is usually shown
                        as a yearly percentage of the loan. This is called the Annual Percentage Rate. Part of each EMI
                        payment goes toward the loan's principal, and another part goes toward the loan's interest. Major
                        loan interest rate agreements have EMIs that start out higher and get lower as the loan goes on.
                        <br><br>
                        On the other hand, the EMI based on the principal amount is lower at the beginning of the term and
                        goes up as the term goes on. There are different ways to figure out interest rates, and depending on
                        which method you use, you might get a good rate for a personal loan.
                        <br><br>
                        Flat interest Rate
                        <br><br>
                        A flat interest rate is a loan rate that stays the same over the life of the loan. At the start of
                        the loan period, the interest is calculated on the whole loan amount. The financial organisation
                        decides when the borrower has to pay back the loan and how much they have to pay each month. It also
                        keeps the borrower's total payment obligation fixed and helps them plan their finances ahead of
                        time. Rates that stay the same are still higher than rates that go down.
                        <br><br>
                        Reducing Interest Rate
                        <br><br>
                        In this case, the interest rate on a personal loan is based on how much is still owed at the end of
                        a certain time period. As was already said, a portion of each EMI payment goes toward the principle
                        and the rest goes toward interest. When figuring out interest, the next calculation is based on the
                        balance of the principal, not the principal amount at the beginning.
                    </p>
                    <p class="mb-0 font-weight-bold title">Flat vs Reducing Rate Calculator</p>
                    <p class="mb-6 font-weight-light txt-444">The Flat vs. Reducing Rate Calculator is a tool that lets
                        you compare the two ways of figuring out interest and choose the one that works well for you. It
                        also lets you see how the interest on your EMIs has changed over time.
                        <br><br>
                        How to Use the Calculator for Flat Rate vs. Interest Rate Reduction
                        <br><br>
                        With the calculator, it's easy to put in the information about your loan and get started. The steps
                        for using Zebu's calculator are as follows:
                        <br><br>
                        Step 1: On the calculator, put in the amount of the loan you took out.
                        <br><br>
                        Step 2: Fill in the information about the loan's term and the agreed-upon interest rate.
                        <br><br>
                        After that, click 'calculate' to see how much loan interest you'll pay.
                        <br><br>
                        Flat rate versus Reducing rate calculator is an internet tool you can use anywhere, anytime.
                        <br><br>
                        It tells you in a split second how much interest you will have to pay on your loans.
                        <br><br>
                        It is also a way to save time and energy that you would normally use to do math by hand.
                        <br><br>
                        It also helps you figure out how much your loan costs.
                        <br><br>
                        It makes it easy for you to plan for a loan.
                        <br><br>
                        To use this calculator, all you need are some basic details about your loan or the loan you want to
                        get.
                        <br><br>
                        It compares the two Major prevalent interest computation methods and finds the difference.
                    </p>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            Principleammount: 10000,
            InterestRate: 10,
            TenurePeriodYear: 2,
            freqSelect: 0,

            emireport: 0,
            emiammount: 0,
            emiinterest: 0,
            emireportt: 0,
            emiammountt: 0,
            emiinterestt: 0,
        }
    },

    mounted() {
        this.compCalculator();
    },

    methods: {
        atChange() {
            setTimeout(() => {
                this.compCalculator();
            }, 600);
        },
        compCalculator() {
            var Principleammounts = parseFloat(this.Principleammount);
            var InterestRates = parseFloat(this.InterestRate);
            var TenurePeriodYears = parseFloat(this.TenurePeriodYear);
            var Principleammountt = parseFloat(this.Principleammount);
            var InterestRatee = parseFloat(this.InterestRate) / (100 * 12);
            var TenurePeriodYearr = parseFloat(this.TenurePeriodYear) * 12;
            // console.log("value emical : ", parseFloat(this.InterestRates));
            var totalIntrest = Math.round((Principleammounts * InterestRates * TenurePeriodYears) / 100);
            var totalRepayamount = Math.round(Principleammounts + totalIntrest);
            var monthlyemi = Math.round(totalRepayamount / (TenurePeriodYears * 12));
            var emical = Math.round(([(parseFloat(Principleammountt) * parseFloat(InterestRatee) * Math.pow(1 + parseFloat(InterestRatee), parseFloat(TenurePeriodYearr)))] / [(Math.pow((1 + parseFloat(InterestRatee)), (parseFloat(TenurePeriodYearr))) - 1)]))
            var emiintrest = (parseFloat(emical) * parseFloat(TenurePeriodYearr)) - parseFloat(Principleammountt);

            this.emireport = monthlyemi.toLocaleString();
            this.emiammount = totalIntrest.toLocaleString();
            this.emiinterest = totalRepayamount.toLocaleString();

            this.emireportt = emical.toLocaleString();
            this.emiammountt = emiintrest.toLocaleString();
            this.emiinterestt = (emical * TenurePeriodYearr).toLocaleString();
        },
    }


}
</script>