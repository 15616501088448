<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-2 font-weight-bold secondary-infont fs-32 lh-32">EPF calculator</p>
                    <p class="title mb-1">Calculate your Employee Provident Fund contributions and final corpus for retirement planning.
          </p>
          <v-divider class="title mb-7"></v-divider>
                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Monthly salary(Basic+DA)</p>
                            <v-text-field type="number" @keyup="atChange()" hide-spin-buttons dense v-model="monthlysalary"
                                block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>

                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Anual increase in salary</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense @keyup="atChange()"
                                        type="number" suffix="%" v-model="anualrate" hide-spin-buttons min="1" max="50"
                                        step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="atChange()" v-model="anualrate" ticks="always" tick-size="6" min="1"
                                        max="50" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>

                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Your age</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense @keyup="atChange()"
                                        type="number" suffix="Yr" v-model="TenurePeriodYear" hide-spin-buttons min="1"
                                        max="50" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="atChange()" v-model="TenurePeriodYear" ticks="always" tick-size="6"
                                        min="1" max="50" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">

                            <v-row class="mb-5 mt-6 mt-md-0">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Interest Rate</p>

                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <div class="cursor-nd">
                                        <v-text-field block flat solo background-color="#E9E9E9" readonly
                                            class="calc-field elevation-0 rounded-pill" hide-details dense
                                            @keyup="atChange()" type="number" suffix="%" v-model="rates" hide-spin-buttons
                                            min="1" max="20" step="1"></v-text-field>
                                    </div>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="atChange()" v-model="srates" ticks="always" tick-size="6" readonly
                                        min="1" max="20" step="1" class="rounded-pill slider-range cursor-nd"
                                        track-color="#EEEEEE" thumb-color="#015FEC" hide-details color='#E9E9E9'></v-slider>
                                </v-col>
                            </v-row>

                            <p class="title font-weight-regular">Estimation</p>

                            <v-list-item class="pa-0 mt-4 mb-6 mb-md-0">
                                <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                    color="#015FEC"></v-card>
                                <v-list-item-content class="pa-0">
                                    <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total maturity
                                        amount</v-list-item-subtitle>
                                    <p class="mb-0 title font-weight-black"><span
                                            class="subtitle-1 font-weight-light">₹</span>{{ emireport ?
                                                emireport : '0.00'
                                            }}</p>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            monthlysalary: 20000,
            anualrate: 8,
            rates: 8.5,
            srates: 9,
            TenurePeriodYear: 25,
            emireport: 0,
        }
    },
    mounted() {
        this.epfCalculator()
    },

    methods: {
        atChange() {
            setTimeout(() => {
                this.epfCalculator()
            }, 600);

        },
        epfCalculator() {
            var amt = parseFloat(this.monthlysalary);
            // console.log("amt",amt)
            this.rate = parseFloat(this.rates) / 100;
            // console.log("intrest",this.rate)
            var anualrates = parseFloat(this.anualrate) / 100;
            // console.log("anualrate",anualrates)
            var year = parseFloat(this.TenurePeriodYear);
            // console.log("TenurePeriodYear",year)
            var age = 60 - year;
            // console.log("age",age)
            var monthlysalry = amt;
            // console.log("monthlysalary",monthlysalry)
            var epftotalcontibution = 0;
            var epfclosing = 0;
            // var epfyearly = 0;
            for (var i = 1; i <= age; i++) {
                var empcontribution = monthlysalry * 0.12 * 12;
                var employercontribution = monthlysalry * 0.0367 * 12;
                epftotalcontibution = Math.round(empcontribution + employercontribution);
                epfclosing = Math.round(((epftotalcontibution + epfclosing) * 0.085) + (epftotalcontibution + epfclosing))
                monthlysalry = (monthlysalry * anualrates) + monthlysalry;
            }

            // console.log('epfclosing', epfclosing)
            this.emireport = epfclosing.toLocaleString();
        },
    }

}
</script>