<template>
  <div class="no-xhide">
    <div data-aos="fade-up" class="pt-4">
      <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl pos-rlt d-none d-md-block">
        <p class="white--text fs-50 font-weight-bold mb-6">Mutual Funds</p>
        <p class="white--text fs-22">
          Mutual Funds: Your Path to Growth.<br>
          Build wealth with SIPs and tax-efficient plans.<br>
          Secure your financial future today!
        </p>

        <div class="pos-abs prd-mf-img text-right">
          <img alt="main-bg.png" src="@/assets/product/mf/main-bg.png" width="90%" />
        </div>
      </v-card>
      <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none text-center">
        <p class="white--text fs-38 font-weight-bold mb-6">Mutual Funds</p>
        <p class="white--text fs-18">Mutual Funds: Your Path to Growth.
          Build wealth with SIPs and tax-efficient plans.
          Secure your financial future today!</p>
      </v-card>
    </div>
    <div class="pt-8 pt-md-16 px-md-16 mb-6">
      <div class="px-16 d-none d-md-block">
        <p class="font-weight-medium black--text"><span class="display-1">Mutual Funds for Financial Growth</span></p>
        <p class="txt-444 font-weight-light title lh-28">Grow your wealth affordably with mutual funds. Simplify your
          journey through SIPs. Build a financially secure future with tax-efficient investments.
        </p>

        <v-btn target="_blank" rel="noopener noreferrer" href="https://oa.mynt.in/?ref=zws" height="48px"
          color="#2A2A2A" class="text-none rounded-pill elevation-0 mb-10 mt-4"><span
            class="white--text subtitle-1 font-weight-medium px-2">Start investing</span></v-btn>
      </div>
      <div class="px-6 px-sm-8 d-md-none">
        <p class="font-weight-medium black--text"><span class="title">Mutual Funds for Financial Growth</span></p>
        <p class="txt-444 font-weight-light fs-18 lh-28">Grow your wealth affordably with mutual funds. Simplify your
          journey through SIPs. Build a financially secure future with tax-efficient investments.
        </p>

        <v-btn target="_blank" rel="noopener noreferrer" href="https://oa.mynt.in/?ref=zws" height="48px"
          color="#2A2A2A" block class="text-none rounded-pill elevation-0 mb-10 mt-4"><span
            class="white--text subtitle-1 font-weight-medium px-2">Start investing</span></v-btn>
      </div>

      <div class="px-6 px-sm-8 px-md-16 mb-6">
        <v-card width="100%" data-aos="fade-down" class="box-s1 elevation-0 rounded-xl pt-16 pb-10 d-none d-md-block"
          outlined>
          <div class="pos-rlt">
            <v-row no-glutters>
              <v-col cols="6">
                <div class="pl-16">
                  <p class="subtitle-2 text-uppercase">What's special</p>
                  <p class="secondary-font-new mb-8">Zebu Makes Investing <br> Simple. Rewarding.</p>
                  <v-card @click="changeTois(l)" v-for="(l, k) in datas" :key="k" data-aos="fade-down"
                    :color="activelist.id == k ? '#444444' : '#ffffff'" width="100%"
                    class="box-s1 brd-r-16 py-3 px-6 mb-4">
                    <p class="mb-0 title font-weight-regular"
                      :class="activelist.id == k ? 'white--text' : 'black--text'">{{ l.tit }}</p>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="px-16">
                  <img data-aos="fade-up" :alt="activelist.image" :src="activelist.image" width="100%" />
                  <div data-aos="fade-down">
                    <p class="font-weight-medium headline black--text">{{ activelist.tit }}</p>
                    <p class="font-weight-light mt-3 black--text fs-17">{{ activelist.sub }}</p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <div class="d-md-none pb-6">
          <p class="subtitle-2 text-uppercase">What's special</p>
          <p class="secondary-fn fs-22 mb-8 lh-32">Zebu makes investing Simple. Rewarding</p>
          <div v-for="(l, k) in datas" :key="k" :class="k != datas.length - 1 ? 'mb-16' : ''">
            <img data-aos="fade-up" :alt="l.image" :src="l.image" width="100%" />
            <div data-aos="fade-down">
              <p class="font-weight-medium fs-24 black--text">{{ l.tit }}</p>
              <p class="font-weight-light black--text fs-18">{{ l.sub }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="py-md-16 rounded-xl">
      <GoalPlanCalculator />
      <!--  <v-card data-aos="fade-down" height="380px"
        class="seceight-bg rounded-xl pl-14 elevation-0 d-none d-md-block overflow-hiddens mb-16" color="#E5EBEC"
        width="100%">
        <v-card width="100%" class="d-inline-flex elevation-0 pos-rlt" color="transparent">
          <v-card width="50%" class="elevation-0 py-16" color="transparent">
            <p class="c-secondary-font">
              Exceptional Performance. <br> Exceptional Results.
            </p>
            <p class="txt-444 font-weight-light fs-20 lh-24">
              Mynt is a sophisticated and <br> modern trading application.
            </p>
            <div class="rounded-0 d-inline-flex">
              <v-card target="_blank" rel="noopener noreferrer" height="45px"
                href="https://play.google.com/store/apps/details?id=com.zebu.mynt" color="#000"
                class="mr-2 rounded-lg elevation-0">
                <img src="@/assets/product/scr-mob1.svg" class="px-1 pt-1" alt="playstore-btn-img">
              </v-card>
              <v-card target="_blank" rel="noopener noreferrer" height="45px"
                href="https://apps.apple.com/in/app/mynt-zebu/id1663709767" color="#000"
                class="mr-2 rounded-lg elevation-0">
                <img src="@/assets/product/scr-mob2.svg" class="px-1 pt-1" alt="playstore-btn-img">
              </v-card>
              <v-card target="_" rel="noopener noreferrer" height="45px"
                href="https://zebuetrade.com/asset/static/Downloads/Apps/ZEBU_INV.exe" color="#000"
                class="rounded-lg elevation-0">
                <img src="@/assets/product/scr-mob3.svg" class="px-1 pt-1" alt="web-btn-img">
              </v-card>
            </div>
          </v-card>
          <v-card width="60%" class="elevation-0 mr-4 overflow-hidden" color="transparent" data-aos="fade-up">
            <img src="@/assets/newimg.png" width="100%" alt="newimg" class="pa-0">
          </v-card>
        </v-card>
      </v-card>
      <v-card data-aos="fade-down"
        class="seceight-bg rounded-0 pt-14 pb-10 mb-10 px-6 px-sm-8 elevation-0 d-md-none text-center" color="#E5EBEC"
        width="100%">
        <v-card width="100%" class="elevation-0" color="transparent">
          <v-card width="100%" class="elevation-0" color="transparent">
            <p class="c-secondary-font">Exceptional Performance. Exceptional Results.</p>
            <p class="txt-444 font-weight-light fs-20 lh-24">
              Mynt is a sophisticated and modern trading application.
            </p>
          </v-card>
          <v-card width="100%" class="elevation-0" color="transparent" data-aos="fade-up">
            <img src="@/assets/newimg.png" width="100%" alt="newimg" class="pa-2">
          </v-card>
          <v-card target="_blank" rel="noopener noreferrer" height="45px"
            href="https://play.google.com/store/apps/details?id=com.zebu.mynt" color="#282828"
            class="rounded-lg elevation-0 mb-3">
            <img src="@/assets/product/scr-mob1.svg" class="px-1 pt-1" alt="playstore-btn-img">
          </v-card>
          <v-card target="_blank" rel="noopener noreferrer" height="45px"
            href="https://apps.apple.com/in/app/mynt-zebu/id1663709767" color="#282828"
            class="rounded-lg elevation-0 mb-3">
            <img src="@/assets/product/scr-mob2.svg" class="px-1 pt-1" alt="playstore-btn-img">
          </v-card>
          <v-card target="_" rel="noopener noreferrer" height="45px"
            href="https://zebuetrade.com/asset/static/Downloads/Apps/ZEBU_INV.exe" color="#282828"
            class="rounded-lg elevation-0">
            <img src="@/assets/product/scr-mob3.svg" class="px-1 pt-1" alt="web-btn-img">
          </v-card>
        </v-card>
      </v-card>
      <div data-aos="fade-up" class="secineight-bg mb-8 mb-md-0">
        <v-card color="transparent" class="secineight-card pos-rlt mx-auto brd-r-32 elevation-0 d-none d-md-block"
          width="90%">
          <img src="@/assets/sec-eight-bg.webp" width="100%" alt="sec-eight-bg">

          <div class="seceight-ctn pos-abs">
            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">
              <v-row no-gutters class="pt-10">
                <v-col cols="9" class="pr-4">
                  <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()" :maxlength="10" :rules="numberis"
                    required hide-spin-buttons flat solo background-color="#595959"
                    class="menu-field-seceight elevation-0 mr-3 rounded-pill caption txt-field"
                    label="Enter mobile number to begin">
                    <template #prepend-inner>
                      <img src="@/assets/phone-icon white.svg" width="100%" class="mx-2" alt="phone-icon">
                    </template>
</v-text-field>
</v-col>
<v-col cols="3">
  <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
    class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
      up</span></v-btn>
</v-col>
</v-row>
</v-form>
</div>
</v-card>
<v-card color="#282828" class="secineight-card pos-rlt mx-auto brd-r-32 elevation-0 d-md-none pa-6" width="90%">
  <img src="@/assets/Msec-eight-bg.svg" width="100%" alt="sec-eight-bg">

  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">

    <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()" :maxlength="10"
      :rules="numberis" required hide-spin-buttons flat solo background-color="#595959"
      class="menu-field-seceight elevation-0 rounded-pill caption txt-field" label="Enter mobile number to begin">
      <template #prepend-inner>
                <img src="@/assets/phone-icon white.svg" width="100%" class="mx-2" alt="phone-icon">
              </template>
    </v-text-field>
    <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
      class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
        up</span></v-btn>
  </v-form>
</v-card>
</div> -->
    </div>

    <div>
      <div class="px-6 mt-0 px-sm-8 mx-7 mb-15">
        <v-card width="100%" data-aos="fade-down" color="#E5EBEC"
          class="box-s1 elevation-0 rounded-xl pt-8 pb-10 d-none d-md-block" outlined>
          <div class="pos-rlt">
            <p class="mb-0 fs-24 text-center black--text font-weight-medium">Frequently Asked Questions</p>

            <p class="font-weight-light text-center mt-2 black--text fs-18">Questions on your mind? Don't
              worry we have
              the
              answers!</p>

            <div v-if="mffaqgres != ''">
              <v-expansion-panels dense v-model="panelselecte" class="elevation-0 rounded-0 pl-8 mt-2 pr-8 mb-md-0">
                <v-expansion-panel class="bgstyle" v-for="(p, h) in mffaqgres" :key="h">
                  <v-expansion-panel-header @click="setScrollwindow()" class="font-weight-bold bgstyle py-0 ml-md-0">
                    <span class="fs-18">{{ h + 1 }}. {{ p.question }}</span>
                    <template v-slot:actions>
                      <v-icon class="d-none d-md-block">mdi-chevron-down</v-icon>
                      <v-icon class="d-md-none">mdi-chevron-down</v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="bgstyle">
                    <p class="mb-0 ml-5 fs-17 font-weight-light" v-html="p.answer"></p>
                  </v-expansion-panel-content>
                  <v-divider></v-divider>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div v-else class="mx-auto text-center ">
              <img class="align-self-stretch mx-auto" width="100px" :src="require('@/assets/no data folder.svg')" />
              <h4 class="txt-999 font-weight-regular">There is no data here yet!</h4>
            </div>
          </div>
        </v-card>
      </div>

      <div class=" d-md-none">
                <p class="mb-0 fs-24 ml-8 black--text font-weight-medium">Frequently Asked Questions</p>

                <p class="font-weight-light ml-8 mt-3 black--text fs-17">Questions on your mind? Don't worry we have the
                    answers!
                </p>
      
                <div v-if="mffaqgres != ''">
                    <v-expansion-panels dense v-model="panelselecte" class="elevation-0 rounded-0 mb-6 ml-3 mb-md-0">
                        <v-expansion-panel class="exp-sty" v-for="(p, h) in mffaqgres" :key="h">
                            <v-expansion-panel-header @click="setScrollwindow()"
                                class="font-weight-bold subtitle-1 py-0 ml-md-3">{{ h + 1
                                }}.
                                {{ p.question }}
                                <template v-slot:actions>
                                    <v-icon class="d-none d-md-block"></v-icon>
                                    <v-icon class="d-md-none">mdi-chevron-down</v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="mb-0 font-weight-light" v-html="p.answer"></p>
                            </v-expansion-panel-content>
                            <v-divider></v-divider>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
                <div v-else class="mx-auto text-center ">
                    <img class="align-self-stretch mx-auto" width="100px"
                        :src="require('@/assets/no data folder.svg')" />
                    <h4 class="txt-999 font-weight-regular">There is no data here yet!</h4>
                </div>
            </div>



      <v-expand-transition class="">
        <v-card v-show="banndialog" class="elevation-0">
          <div class=" ">

            <v-card v-if="showbox" elevation="2" color="#E5EBEC" width="100%" class="webbannerinshow rounded-xl ">
              <div v-if="fromdatares">
                <div class="d-flex">
                  <div class="ml-4 ">
                    <p class="font-weight-bold mb-0 fs-18 mt-4 black--text">Talk to an Expert</p>
                    <p class="font-weight-semibold mb-4 fs-18 caption black--text">Tailored to your needs</p>
                  </div>

                  <v-btn class="mr-4 mt-4 ml-auto" icon @click="closecard()">
                    <v-icon color="black">mdi-close</v-icon>
                  </v-btn>
                </div>

                <div class="px-4 pb-4">
                  <form @submit.prevent="formadataipo">
                    <v-text-field v-model="frommobile" hide-spin-buttons maxlength="10" :disabled="loader"
                      :rules="mobileRules" required block flat solo background-color="#ffffff"
                      class="elevation-0 rounded-pill caption" label="Enter your mobile number">
                      <template #prepend-inner>
                        <img src="@/assets/contactus/phone.svg" width="100%" class="mx-2" alt="phone-icon" />
                      </template>
                    </v-text-field>
                    <span class="ml-2 subtitle-2 font-weight-bold">Language Preferred </span>
                    <v-radio-group :rules="radiobuttonrule" v-model="row" row color="black" class="ml-2">
                      <v-radio label="English" value="English" class="small-label" color="black"></v-radio>
                      <v-radio label="Hindi" value="Hindi" class="small-label" color="black"></v-radio>
                      <v-radio label="Tamil" value="Tamil" class="small-label" color="black"></v-radio>
                    </v-radio-group>
                    <v-row>
                      <v-col cols="12">
                        <v-btn type="submit" block large rounded :color="valid ? '#FFF07E' : '#FFF07Ec2'"
                          :loading="loader" height="48px" class="elevation-0 mr-7  text-none">
                          <span class="black--text px-7">Book a Free Call</span>
                        </v-btn>
                      </v-col>

                    </v-row>
                  </form>
                  <p class="text-center mb-0 font-weight-bold">Or</p>

                  <v-btn @click="getCallnew()" block large rounded :color="valid ? '#000' : '#000'" :loading="loader"
                    class="elevation-0  freeaccoucss text-none">
                    <span class="white--text freeaccoucss ">Open a Free Demat Account Today!</span>
                  </v-btn>
                </div>
              </div>
              <div class="pa-10 text-center" v-if="thankyoumes">

                <v-icon size="70 " class="mt-10" color="green">mdi-check-circle-outline</v-icon>
                <p class="mt-14 mb-14 font-weight-bold title" color="green">Thank you, Our expert will get in touch with
                  you
                  shortly.</p>
              </div>
            </v-card>


            <div class="webbannerins" v-if="signupbtn">
              <v-btn @click="opencard()" class=" mx-2 white--text text-none elevation-0  " rounded large
                :color="valid ? '#0037B7' : '#0037B7'">
                <svg width="25" height="20" viewBox="0 0 460 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M71.25 0H336.75L337.75 0.5L346.25 2.25L352.25 4L357.5 6L363.75 9L367.75 11.25L372 14L375 16.25L378.5 19L382.25 22.5L386 26.25L390.5 31.75L393.5 36L396.5 41L399 45.75L401.5 51.75L403.5 57.75L405 63.75L406 70.25L406.5 76.25V205L405.75 208.75L404.5 211.75L402.75 214.75L400.5 217.5H400V218L397.25 220L393.75 221.75L390.25 222.75L388.5 223H384L380.5 222.25L377.5 221L374.5 219.25L371.5 216.5L369.25 213.5L367.5 210L366.5 206.25L366.25 203.75L366 79L365.25 72L363.25 65.25L361.25 61L358.5 56.5L355.25 52.75L354.25 51.5L350.75 48.75L348.25 46.75L342.75 43.75L336 41.5L333.75 41L328.75 40.5L323.25 40.25H82.75L75 40.75L70 41.75L64.75 43.5L58.75 46.75L54.75 50L52.25 52.25L50.25 54.5L47.25 58.5L44.25 64L42.25 69.75L41.25 75.25L41 78V433.75L41.5 438.5L42.5 443L44.25 448L46.75 452.75L49 456L52.25 459.75L55.25 462.5L59 465.25L64.5 468.25L68 469.5L71.25 470.5L76.75 471.25L79.5 471.5L147 471.75L152 472.25L155.75 473.5L159.5 475.75L162.5 478.5L164.5 481.25L166.5 485.25L167.5 489.5V494.25L166.75 497.75L165.5 501L163.25 504.5L161 507L157.75 509.25L154.25 511L151.75 511.75L151.5 512H70.5L70 511.5L62.25 510L55.75 508.25L48.5 505.5L41.5 502L36.25 498.75L31.75 495.5L29 493.25L25.75 490.25L23.75 488.5L20.25 484.75L16.75 480.5L13.75 476.25L11.75 473L9.75 469.5L7 464L4.5 457.5L2.5 450.5L1.5 445.5L0.75 440L0.25 431V80L0.75 71.5L2.25 62.5L4.25 55.25L6.75 48.5L10 41.75L13.5 36L16 32.5L18.5 29.25L20.75 26.75L22.5 24.75L27.25 20L29.25 18.25L32.25 16L36 13.25L43 9L49.25 6L56 3.5L63.75 1.5L70.75 0.25L71.25 0Z"
                    fill="white" />
                  <path
                    d="M398 271.75H401.25L407.5 272.25L413 273.25L418.25 274.75L423.5 276.75L427.75 278.75L432.75 281.75L437 285L439.75 287.25L445 292.5L448.25 296.75L450.75 300.5L453 304.5L455 308.75L457.25 315L458.75 321.5L459.5 327.75V336.5L458.5 343.75L457 349.75L455 355.25L452.5 360.5L450 364.75L447.5 368.25L445.25 371L442.5 374L440.75 376L439.75 377H439.25L438.75 378L437 379.5L334.5 482H334L333.5 483H333V483.5L330.5 485.5L327.75 487.25L324.25 488.75L316.75 491L257 507.5L243.75 511.25L240.75 512H233.5L228.5 510L226 508.5L223.5 506.5V506H223L220.5 502.75L218.5 498.75L217.75 496.5L217.25 492.75V490.75L218.25 485.5L221.5 474.75L225 463.25L228.25 452.5L231.75 441L236 427L240 413.75L242 407.25L244 402.75L246.25 400L249 397L298.5 347.5H299L299.5 346.5H300L300.5 345.5H301L301.5 344.5H302L302.5 343.5H303L303.25 342.75L304.75 341.5L306.25 339.75L308 338.25L309.25 336.75L311 335.25L312.25 333.75L314.25 332L358.5 287.75L360.5 286L365 282.5L370.25 279.25L374.25 277.25L377.75 275.75L383 274L388.5 272.75L393.75 272L398 271.75ZM396.5 312.25L392.5 313.25L389 315L385.75 317.5L382 321.25L382.25 322.25L384 324.25L407.25 347.5L408.75 348.75L409.5 349.5L410.5 349L414.75 344.75L417 341.25L418.25 338.5L419 335.75L419.25 331L418.75 327.25L417.5 323.75L415.75 320.5L413 317.25L409.5 314.75L406.25 313.25L402 312.25H396.5ZM353 350.25L351 352V352.5H350.5V353H350V353.5H349.5V354H349V354.5H348.5V355H348V355.5H347.5V356H347V356.5H346.5V357H346V357.5H345.5V358H345V358.5L344 359V359.5L343 360V360.5H342.5V361H342V361.5H341.5V362H341V362.5L340 363V363.5L339 364V364.5L338 365L279.25 423.75L277.5 429L274.25 439.75L268.5 458.75L267.5 461.75V462.5L269.5 462.25L305.75 452.25L308 451.25L310.5 449L381 378.5L380.5 377.25L354.25 351H353.75L353.5 350.25H353Z"
                    fill="white" />
                  <path
                    d="M100.75 199.75H305.75L311 200.25L314.75 201.5L318.5 203.75L321.5 206.5L324 210L325.75 214L326.5 217.5V222.25L325.75 225.75L324.5 229L322.75 231.75L320.5 234.5L317.25 237L313.75 238.75L310 239.75L307.75 240H98.75L95.5 239.5L92.25 238.5L89.25 236.75L86 234L83.5 231L81.5 227L80.5 223.25L80.25 221.25V218.75L81 214.5L82.25 211.25L84 208.25L87 205L89 203.25L92.75 201.25L96 200.25L100.75 199.75Z"
                    fill="white" />
                  <path
                    d="M100.75 119.75H305.75L311 120.25L314.75 121.5L318.5 123.75L321.5 126.5L324 130L325.75 134L326.5 137.5V142.25L325.75 145.75L324.5 148.75L323 151.5L320.25 154.75L317.25 157L313.75 158.75L310 159.75L307.75 160H98.75L95.5 159.5L92.25 158.5L89.25 156.75L86 154L83.5 151L81.25 146.25L80.5 143.25L80.25 141.25V138.75L81 134.5L82.25 131.25L84 128.25L87 125L89 123.25L92.75 121.25L96 120.25L100.75 119.75Z"
                    fill="white" />
                  <path
                    d="M100.75 279.75H224.5L230 280.25L233.25 281.25L237.25 283.5L240.25 286.25L242 288.25L244 291.75L245.25 295.5L245.75 298.5V301.25L245 305.5L243.75 308.75L241.75 312L239 315L236 317.25L232.75 318.75L229 319.75L227 320H98.75L95.5 319.5L92.25 318.5L88.5 316.25L85.5 313.5L83.25 310.5L81.25 306.25L80.5 303.25L80.25 301.25V298.75L81 294.5L82.25 291.25L84 288.25L87 285L89 283.25L92.75 281.25L96 280.25L100.75 279.75Z"
                    fill="white" />
                </svg>
                <!-- <v-icon size="22" color="white" class="mr-2"> </v-icon> -->
                <span class="freeaccoucss">Talk to an Expert</span>
              </v-btn>
            </div>


          </div>
        </v-card>
      </v-expand-transition>

    </div>
  </div>
</template>

<script>
import GoalPlanCalculator from "../Calculators/GoalPlanCalculator.vue";
import { ipolpdatastore } from "../../apiurl";

export default {
  components: { GoalPlanCalculator },
  data: () => ({
    datas: [
      { id: 0, image: require("@/assets/product/mf/l1.png"), tit: "Expert Management", sub: "Mutual funds are overseen by SEBI-certified professionals who actively work to deliver returns that exceed market benchmarks." },
      { id: 1, image: require("@/assets/product/mf/l2.png"), tit: "Tax Advantages", sub: "Mutual funds offer significant tax efficiency through indexation, helping you save an average of 3-4% on taxes compared to other investment options." },
      { id: 2, image: require("@/assets/product/mf/l3.png"), tit: "Systematic Investment Plans", sub: "A smart approach to investing, SIPs allow you to allocate capital effectively, aligning with your financial goals and risk preferences for steady growth." },
      { id: 3, image: require("@/assets/product/mf/l4.png"), tit: "Direct Investment - Save 2%", sub: "With Zebu’s direct mutual funds, you can avoid high expense ratios of regular funds, allowing your investments to grow and compound more efficiently." },
      { id: 4, image: require("@/assets/product/mf/l5.png"), tit: "Affordable Investments", sub: "Mutual funds offer affordability, allowing you to start investing with Zebu for as little as ₹500. Begin small and watch your wealth grow over time." },
    ],
    activelist: {},

    valid: true,
    mobile: "",
    numberis: [(v) => !!v || "Your mobile number is required", (v) => /^[6-9][0-9]{9}$/.test(v) || "mobile number must be 10 Digit Number"],
    nameRules: [(v) => !!v || "Your Name is required", (v) => (v && v.length >= 3) || "Name must be more than 3 characters"],
    emailRules: [(v) => !!v || "Your E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    mobileRules: [(v) => !!v || "Enter your mobile number to proceed", (v) => /^[6-9][0-9]{9}$/.test(v) || "Enter a valid mobile number"],
    radiobuttonrule: [v => !!v || 'Please select a language'],
    snackbar: false,
    snackbarclr: "#000",
    snackmsgbar: "",
    banndialog: true,
    showbox: true,
    signupbtn: false,
    row: "",
    fromdatares: true,
    thankyoumes: false,
    mffaqgres: [],
    loader: false,
    frommobile: "",
    panelselecte: "",
    
  }),

  mounted() {
    this.activelist = this.datas[0];
    this.mffaqapi()
  },
  methods: {
    mffaqapi() {
      const axios = require('axios');

      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://sess.mynt.in/strapi/wsfaqs',
        headers: {}
      };
      var axiosThis = this
      axios.request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          if (response.data && response.data.data) {
            axiosThis.onlinepay(response.data.data[0].attributes);
          }
          // axiosThis.onlinepay = response.data.data.attributes.mffaq
          // console.log("axiosThis.onlinepay",axiosThis.onlinepay);

        })
        .catch((error) => {
          console.log(error);
        });


    },
    onlinepay(datas) {
      if (datas) {
        this.mffaqgres = datas.mffaq


      }
    },
    opencard() {
      this.signupbtn = false
      this.showbox = true
    },
    closecard() {
      this.showbox = false
      this.signupbtn = true

    },
    getCallnew() {
      if (this.frommobile.length >= 10) {
        window.open(`https://oa.mynt.in/?ref=zws-mf&mobile=${this.frommobile}`);
        this.frommobile = ""
      }
      else {
        window.open(`https://oa.mynt.in/?ref=zws-mf`);

      }

    },
    NumberValid($event) {
      if (($event.keyCode >= 48 && $event.keyCode <= 57) || ($event.keyCode >= 96 && $event.keyCode <= 105)) {
        // 0-9 only
      } else {
        $event.preventDefault();
      }
    },
    formadataipo() {
      if (this.frommobile != "" && this.row != "") {
        this.loader = true;
        const axios = require("axios");
        let data = JSON.stringify({
          client_name: "MF Book a Call",
          mobile_number: this.frommobile,
          email: this.row,
          source: "Mutual Funds",
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${ipolpdatastore}/add`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        var axiosThis = this;
        axios
          .request(config)
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            axiosThis.loader = false;
            axiosThis.dialog = false;
            if (response.data.stat == "Ok") {
              axiosThis.fromdatares = false
              axiosThis.thankyoumes = true
              setTimeout(() => {
                axiosThis.thankyoumes = false
                this.fromdatares = true;
              }, 5000);
              }
              else if(response.data.msg == "number already exist"){
                axiosThis.fromdatares = false
              axiosThis.thankyoumes = true
                setTimeout(() => {
                axiosThis.thankyoumes = false
                axiosThis.fromdatares = true;
              }, 5000);
            }
            else {
              axiosThis.snackbar = true;
              axiosThis.snackbarclr = "red";
              axiosThis.snackmsgbar = response.data;
            }
            axiosThis.formaname = "";
            axiosThis.frommobile = "";
            axiosThis.formemail = "";
            axiosThis.row = ""
          })

          .catch((error) => {
            console.log(error);
            axiosThis.dialog = false;
            axiosThis.loader = false;
            axiosThis.snackbar = true;
            axiosThis.snackbarclr = "error";
            (axiosThis.snackmsgbar = error), (axiosThis.formaname = "");
            axiosThis.frommobile = "";
            axiosThis.formemail = "";
            axiosThis.row = ""

          });
      }
    },
    getCall() {
      if (this.$refs.form.validate() == true) {
        window.open(`https://oa.mynt.in/?ref=zws&mobile=${this.mobile}`);
        this.$refs.form.reset();
      }
    },
    changeTois(l) {
      this.activelist = l;
    },
  },
};
</script>
