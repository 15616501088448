<template>
    <div class="no-xhide">
        <div class="mt-8 mt-md-16">
            <v-row no-glutters>
                <v-col md="5" class="d-none d-md-flex">
                    <v-container fill-height>
                        <div class="ma-auto">
                            <p data-aos="fade-up" class="mb-md-5 font-weight-bold display-2 txt-terity">Life at Zebu</p>

                            <p data-aos="fade-up" class="title font-weight-medium lh-28 mb-0 mb-md-0">At Zebu, we foster a flat hierarchy that encourages growth and innovation for every team member. If you thrive on challenges and value a dynamic, rewarding environment, there's always a spot for you at Zebu.
                            </p>
                        </div>
                    </v-container>
                </v-col>
                <v-col cols="12" class="d-md-none">
                    <div class="px-6 px-sm-8">
                        <p data-aos="fade-up" class="mb-3 font-weight-bold fs-38 txt-terity">Life at Zebu</p>

                        <p data-aos="fade-up" class="fs-18 font-weight-medium lh-24 mb-0 mb-md-0">At Zebu, we foster a flat hierarchy that encourages growth and innovation for every team member. If you thrive on challenges and value a dynamic, rewarding environment, there's always a spot for you at Zebu.
                        </p>
                    </div>
                </v-col>
                <v-col cols="12" md="7">
                    <v-card data-aos="zoom-in-left" class="elevation-0 rounded-xl px-6 px-sm-8 pl-md-16">
                        <img width="100%" src="@/assets/life-at-zebu.jpg" alt="life-at-zebu">
                    </v-card>

                </v-col>
            </v-row>
        </div>
        <div class="pt-10 mt-md-16">
            <v-card class="elevation-0 rounded-0 text-center d-none d-md-block" width="100%">
                <p class="mb-8">OUR CORE VALUES</p>
                <p class="secondary-font fw-6 mb-0 fs-32 lh-28">What Drives Us? .</p>
                <p class="secondary-font font-weight-bold">Our Passion for Excellence</p>
            </v-card>
            <v-card class="elevation-0 rounded-0 text-center d-md-none px-4 px-sm-6" width="100%">
                <p class="mb-4">OUR CORE VALUES</p>
                <p class="secondary-fn fs-28 font-weight-regular lh-28 mb-0">What Drives Us? Our Passion for Excellence.</p>
            </v-card>
            <v-row no-glutters class="py-10 pb-md-16">
                <v-col data-aos="fade-up" v-for="(o, u) in ourvalitems" :key="u" cols="12" md="4">
                    <v-card  class="elevation-0  secseven-card rounded-lg">
                    <div data-aos="zoom-in-right" class="pos-rlt d-none d-md-flex">
                        <v-card :color="o.color" height="111" width="81"
                            class="life-our-val-crd elevation-0 rounded-lg pos-abs opc-10">
                        </v-card>
                        <div>
                            <v-list-item class="pr-0 pt-1">
                                <v-list-item-content>
                                    <v-list-item-title :style="`color:${o.color};`" class="font-weight-medium headline">{{
                                        o.tit }}</v-list-item-title>
                                    <p class="mt-2 txt-444 font-weight-light title lh-32">{{ o.sub }}</p>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                    </div>
                </v-card>
                    <div class="px-6 px-sm-8 px-md-0 d-md-none">
                        <v-card :color="o.opclr" width="100%" class="elevation-0 rounded-xl pa-6 text-center">
                            <p :style="`color:${o.color};`" class="font-weight-medium title mb-2">{{
                                o.tit }}</p>
                            <p class="mb-0 txt-444 font-weight-light fs-18 lh-24">{{ o.sub }}</p>
                        </v-card>
                    </div>
                </v-col>
            </v-row>
        </div>

        <div class="pb-md-16">
            <v-card width="100%" color="#ECF4FF" class="elevation-0 rounded-xl d-none d-md-flex pt-16" outlined>
                <div class="feat-bg-ctn">
                    <v-row no-glutters>
                        <v-col cols="12" md="6">
                            <div data-aos="fade-down" class="px-16">
                                <p class="subtitle-2 text-uppercase mb-4 font-weight-regular ls-1 lh-38">Employee Benefits
                                </p>
                                <p class="secondary-font mb-8">A Dynamic, <br> Rewarding <br> & Workplace</p>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="pr-16 pt-4">
                                <v-list-item data-aos="fade-left" v-for="(item, j) in Emp_benefit" :key="j" two-line
                                    class="mb-3">
                                    <v-list-item-avatar class="mt-0 pos-ini" tile>
                                        <img class="pos-abs top-16" :src="item.img" :alt="item.img" />
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold headline">{{
                                            item.tit }}</v-list-item-title>
                                        <p class="pt-3 font-weight-light fs-18 lh-24">{{ item.sub }}</p>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <div data-aos="fade-right" class="ben-bg-img pos-abs">
                    <img src="@/assets/product/feat-bg.svg" alt="feat-bg">
                </div>
            </v-card>
            <v-card width="100%" color="#ECF4FF" class="elevation-0 rounded-0 d-md-none pt-6" outlined>
                <div>
                    <v-row no-glutters>
                        <v-col cols="12">
                            <div data-aos="fade-down" class="px-6 px-sm-8">
                                <p class="subtitle-2 text-uppercase mb-4 font-weight-regular ls-1 lh-38">Employee Benefits
                                </p>
                                <p class="secondary-fn fs-32 font-weight-regular lh-32">Dynamic, challenging &
                                    rewarding</p>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div v-for="(item, j) in Emp_benefit" :key="j" class="px-6 px-sm-8">
                                <img width="32px" :src="item.img" :alt="item.img" />
                                <v-list-item data-aos="fade-left" two-line class="px-0">
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold title">{{
                                            item.tit }}</v-list-item-title>
                                        <p class="pt-3 font-weight-light fs-16 lh-24">{{ item.sub }}</p>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </div>
        <div class="py-10 py-md-16">
            <v-row no-glutters class="d-none d-md-flex">
                <v-col cols="12" md="4">
                    <v-container fill-height>
                        <div data-aos="fade-down" class="mt-auto mb-6 ">
                            <p class="font-weight-bold fs-44 lh-48">  Where Ideas Come to Life</p>
                            <p class="font-weight-medium headline lh-28">Our sanctum of work and play.<br>
                                Our bodhi.</p>
                        </div>
                    </v-container>
                </v-col>
                <v-col cols="12" md="8">
                    <div class=" hover-containerlife">
                        <v-btn @click="loveTo()" class="firstbtn hover-button left-button" icon fab outlined>
    <v-icon size="40">mdi-chevron-left</v-icon>
  </v-btn>
                 
                            <v-card id="love" width="100%" v-dragscroll.x
                              class="d-inline-flex elevation-0 overflow-x-auto  rounded-lg elevation-0 mr-4" 
                               >
                              <v-card  data-aos="flip-right" class="elevation-0" v-for="(l, y) in our_lifeitem" :key="y"
                              :class="our_lifeitem.length - 1 != y ? 'mr-8' : 'mr-0'" width="100%">
                                <img class="rounded-xl" height="320px" width="240px" :src="l" :alt="l">
                            </v-card>
                            </v-card>
                    
                        <v-btn @click="loveBk()" class="firstbtn hover-button right-button" icon fab outlined>
    <v-icon size="40">mdi-chevron-right</v-icon>
  </v-btn>
                    </div>
                </v-col>
            </v-row>
            <div class="d-md-none px-6 px-sm-8">
                <div data-aos="fade-down" class="mb-6">
                    <p class="font-weight-bold fs-32 lh-32">Where Ideas Come to Life</p>
                    <p class="font-weight-medium title lh-28">Our sanctum of work and play. <br> Our bodhi.</p>
                </div>
                <div class="overflow-x-auto cust-scroll">
                    <div class="d-inline-flex mb-4">
                        <div data-aos="fade-left" v-for="(l, y) in our_lifeitem" :key="y"
                            :class="y != our_lifeitem.length - 1 ? 'mr-4' : 'mr-0'">
                            <img class="rounded-lg" height="196px" :src="l" :alt="l">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pt-md-10 pb-8 pb-md-16" v-if="allcareers != 'null'">
            <v-container>
                <div class="text-center mb-4 d-none d-md-block">
                    <p class="font-weight-bold fs-48 lh-48">Join the Zebu Team</p>
                    <p class="font-weight-bold headline lh-28">Ready to take the leap or be the change? <br> Your journey starts here at Zebu.</p>
                </div>
                <div class="px-6 px-sm-8 px-md-0 mb-6 d-md-none">
                    <p class="font-weight-bold fs-32 lh-32">Join the Zebu Team</p>
                    <p class="font-weight-medium title lh-24">Ready to take the leap or be the change? Your journey starts here at Zebu.</p>
                </div>
                <v-row v-if="!jobloader" class="px-6 px-sm-8 px-md-16 mx-md-16 pt-md-10 pb-6">
                    <v-col cols="12" md="6" class="pr-md-6" v-for="(s, p) in allcareers" :key="p">
                        <v-card @click="jobTo(s)" data-aos="flip-up" width="100%" class="box-s1 hcard rounded-xl px-2">
                            <v-list-item class="pr-0 py-2">
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-bold fs-18">{{ s.attributes.jobtitle
                                    }}</v-list-item-title>
                                    <p class="mt-1 mb-0 font-weight-regular">{{ s.attributes.joblocation }}</p>
                                </v-list-item-content>
                                <v-btn text plain color="#0037B7"><span
                                        class="font-weight-bold fs-18 text-none">Apply</span></v-btn>
                            </v-list-item>
                        </v-card>
                    </v-col>
                </v-row>
                <v-container v-else fill-height>
                    <v-card class="elevation-0 mx-auto my-16">
                        <v-progress-circular size="80" indeterminate color="#1e53e5"></v-progress-circular>
                    </v-card>
                </v-container>
            </v-container>
        </div>
    </div>
</template>
  
<script>
import axios from "axios";
import { strapi } from '../apiurl'

export default {

    data: () => ({
        jobloader: false,
        allcareers: [],

        ourvalitems: [
            { color: '#1AB700', opclr: '#E8F8E5', tit: 'Customer-First Approach.', sub: " Our mission is to simplify investing and solve our customers’ biggest challenges." },
            { color: '#2E65F6', opclr: '#EAF0FE', tit: 'Unity in Action.', sub: "We work as a united team, ensuring that everyone achieves success together." },
            { color: '#F05D0B', opclr: '#FEEFE7', tit: 'Innovation in Action.', sub: "We act swiftly, think strategically, and continually innovate to achieve meaningful breakthroughs." },

        ],
        Emp_benefit: [
            { img: require('@/assets/zebuLife/eb1.svg'), tit: 'Work-Life Balance', sub: " A balanced work-life experience where professional and personal harmony is celebrated." },
            { img: require('@/assets/zebuLife/eb2.svg'), tit: 'Comprehensive Health Cover', sub: ' Robust health insurance to protect you and your loved ones.' },
            { img: require('@/assets/zebuLife/eb3.svg'), tit: 'Industry-Leading Compensation', sub: 'Competitive salaries and benefits that set industry benchmarks. ' },
            { img: require('@/assets/zebuLife/eb4.svg'), tit: 'Inclusive, Flat Structure', sub: 'A zero-hierarchy culture where collaboration and equal opportunities thrive.' },
        ],
        our_lifeitem: [
            require("@/assets/our-life3.jpg"),
            require("@/assets/our-life4.jpg"),
            require("@/assets/our-life1.jpg"),
            require("@/assets/our-life2.jpg"),
        ],
        joinustech: [
            { type: 'Relationship Manager', loc: 'Hyderabad' },
            { type: 'Branch Manager', loc: 'Hyderabad' },
            { type: 'Customer champion', loc: 'Hyderabad' },
            { type: 'Sales assistant ', loc: 'Hyderabad' },
            { type: 'UI developer', loc: 'Hyderabad' },
            { type: 'Backend engineer', loc: 'Hyderabad' },
            { type: 'Technical Manager', loc: 'Hyderabad' },
            { type: 'Frontend engineer ', loc: 'Hyderabad' },
        ],
    }),
    mounted() {
        this.jobloader = true;
        this.allcareers = [];
        let config = {
            method: 'get',
            url: `${strapi}/careers`,
        };

        let axiosThis = this;
        axios.request(config)
            .then((response) => {
                if (response.data.data) {
                    axiosThis.allcareers = response.data.data;
                } else {
                    axiosThis.allcareers = 'null'
                }
                axiosThis.jobloader = false;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    methods: {
        jobTo(s) {
            this.$router.push({ name: 'career', params: { id: s } });
        },
        loveTo() {
      const element = document.getElementById("love");
      element.scrollBy({
        left: -600,
        behavior: "smooth",
      });
    },

    loveBk() {
      const element = document.getElementById("love");
      element.scrollBy({
        left: 600,
        behavior: "smooth",
      });
    },
    }

}
</script>