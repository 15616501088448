<template>
    <div class="no-xhide">
        <div data-aos="fade-up">
            <img src="@/assets/about-us-bg.svg" width="100%" alt="about-us-bg" class="d-none d-md-flex">
            <img src="@/assets/mabout-us-bg.svg" width="100%" alt="about-us-bg" class="d-md-none">
        </div>

        <div id="founder" class="pb-md-16">
            <v-row no-glutters>
                <v-col cols="12" md="6" class="px-0 px-md-3">
                    <div data-aos="zoom-in-right" class="pos-rlt pt-6 pt-md-0">
                        <img src="@/assets/abt-MD-img.png" class="md-image ml-auto pr-16 d-none d-md-flex" alt="abt-MD-img">
                        <img src="@/assets/mabt-MD-img.png" class="d-md-none px-4" width="100%" alt="abt-MD-img">

                        <!-- <div class="play-MD-img pos-abs d-none d-md-flex">
                            <v-btn fab icon>
                                <img src="@/assets/abt-play-img.svg" width="100px" alt="abt-play-img">
                            </v-btn>
                        </div>
                        <div class="mplay-MD-img pos-abs d-md-none">
                            <v-btn fab icon>
                                <img src="@/assets/abt-play-img.svg" width="70px" alt="abt-play-img">
                            </v-btn>
                        </div> -->
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div data-aos="fade-up" class="mt-md-16 pt-md-16 px-7 px-sm-9 px-md-0">
                        <p class="mb-0 mb-md-1 font-weight-bold secondary-infont fs-32">What Drives Zebu Forward?</p>
                        <p class="mb-md-16 title font-weight-regular">By staying true to our core values and consistently delivering on them.</p>
                        <v-row class="mt-6 mt-md-16" no-glutters>
                            <v-col data-aos="flip-down" class="mb-md-10" v-for="(t, s) in tickitems" :key="s" cols="12"
                                md="6">
                                <img class="d-none d-md-flex" :src="t.img" :alt="t.img">
                                <img class="d-md-none" width="50px" :src="t.img" :alt="t.img">

                                <v-list-item class="px-0">
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold d-none d-md-flex fs-22">{{ t.tit
                                        }}</v-list-item-title>
                                        <v-list-item-title class="font-weight-bold d-md-none title">{{ t.tit
                                        }}</v-list-item-title>
                                        <p class="mt-3 d-none d-md-flex">{{ t.sub }}</p>
                                        <p class="mt-2 fs-18 d-md-none mb-0">{{ t.sub }}</p>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div class="py-6 py-md-16">
            <v-row no-glutters class="flex-column-reverse flex-md-row">
                <v-col cols="12" md="6" class="pr-md-16 px-0 px-md-3">
                    <div class="px-10 px-sm-12 pl-md-0">

                    <p data-aos="fade-right" class="mb-4 d-none d-md-flex mt-md-0 font-weight-bold fs-38">One Mission, Shared Growth</p>
                    <p data-aos="fade-right" class="mb-3 d-md-none font-weight-bold fs-28">One Mission, Shared Growth</p>

                    <p data-aos="fade-right" class="font-weight-bold d-none d-md-flex title mb-4 lh-24">Dedicated to bridging technological and service gaps 
                        that hold traders back.</p>
                    <p data-aos="fade-right" class="font-weight-bold d-md-none fs-18 lh-28">Dedicated to bridging technological and service gaps
                        that hold traders back.</p>

                    <p data-aos="fade-right" class="mb-4 txt-444 font-weight-light d-none d-md-flex fs-18 lh-24">Zebu was created to address the challenges faced by Indian traders and help them achieve financial growth.</p>
                    <p data-aos="fade-right" class="mb-4 txt-444 font-weight-light d-md-none fs-18 lh-28">Zebu was created to address the challenges faced by Indian traders and help them achieve financial growth.</p>

                    <p data-aos="fade-right" class="mb-4 txt-444 font-weight-light d-none d-md-flex fs-18 lh-24">
                        Our journey began with a mission to close the service and technology gaps that restricted traders and investors from reaching their full potential.
                    </p>
                    <p data-aos="fade-right" class="mb-4 txt-444 font-weight-light d-md-none fs-18 lh-28">
                        Our journey began with a mission to close the service and technology gaps that restricted traders and investors from reaching their full potential.
                    </p>

                    <p data-aos="fade-right" class="txt-444 font-weight-light d-none d-md-flex fs-18 lh-24">
                        We continuously develop products and services tailored to meet traders' needs, from robust trading platforms across devices to single-margin trading across asset classes and tools for research analysts and advisors.
                    </p>
                    <p data-aos="fade-right" class="txt-444 font-weight-light d-md-none fs-18 lh-28">
                        We continuously develop products and services tailored to meet traders' needs, from robust trading platforms across devices to single-margin trading across asset classes and tools for research analysts and advisors.
                    </p>
                </div>

                </v-col>
                <v-col cols="12" md="6" class="px-0 px-md-3">
                    <v-card data-aos="zoom-in-left" class="elevation-0 rounded-xl d-none d-md-flex">
                        <!-- <img src="@/assets/abt-allinone-img.jpg" class="abt-allinone" alt="abt-allinone-img"> -->
                        <img src="@/assets/life-at-zebu.jpg" class="abt-allinone mt-3" alt="abt-allinone-img">

                    </v-card>
                    <v-card data-aos="zoom-in-left" class="elevation-0 rounded-0 d-md-none">
                        <!-- <img src="@/assets/abt-allinone-img.jpg" width="100%" alt="abt-allinone-img"> -->
                        <img src="@/assets/life-at-zebu.jpg" width="100%" alt="abt-allinone-img">

                    </v-card>
                </v-col>
            </v-row>
        </div>

        <div id="motto" class="mb-md-16">
            <v-card data-aos="fade-up" class="elevation-0 rounded-xl px-16 pt-16 pb-14 pos-rlt d-none d-md-block"
                color="#C9EFCD">
                <div>
                    <p class="subtitle-2 text-uppercase mb-4 font-weight-regular ls-1 lh-38">Our Philosophy</p>
                    <p class="secondary-font mb-8">Our mission is to empower traders and <br> investors  to realize their potential  <br> through intuitive technology <br>and practical solutions.</p>
                </div>
                <v-divider class="mt-10 mb-6"></v-divider>

                <div class="d-inline-flex pt-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M9 23H1C0.44775 23 0 22.5527 0 22V12C0 6.95605 1.89014 3.61719 5.61768 2.07568C6.12793 1.86572 6.7124 2.10742 6.92432 2.61768C7.13526 3.12842 6.89258 3.71289 6.38233 3.92432C3.67383 5.04395 2.24365 7.36328 2.02881 11H9C9.55225 11 10 11.4478 10 12V22C10 22.5527 9.55225 23 9 23Z"
                            fill="#212121" />
                        <path
                            d="M23 23H15C14.4477 23 14 22.5527 14 22V12C14 6.95605 15.8901 3.61719 19.6177 2.07568C20.1265 1.86572 20.7124 2.10742 20.9243 2.61767C21.1353 3.12841 20.8926 3.71288 20.3823 3.92431C17.6738 5.04395 16.2437 7.36328 16.0288 11H23C23.5522 11 24 11.4478 24 12V22C24 22.5527 23.5522 23 23 23Z"
                            fill="#212121" />
                    </svg>
                    <p class="pl-6 fs-20 lh-32 mb-0"><span class="txt-444 font-weight-regular">Zebu was established to address the challenges faced by Indian traders in achieving financial growth.</span> <br> <span
                            class="font-weight-black">Vijayakumar, </span> <span class="font-weight-medium">
                                Founder of Zebu, envisioned creating a trader-first platform.</span></p>
                </div>
                <div data-aos="fade-right" class="ben-bg-img0 pos-abs">
                    <img src="@/assets/product/feat-bg.svg" alt="feat-bg">
                </div>
            </v-card>
            <v-card data-aos="fade-up" class="elevation-0 rounded-0 px-7 px-sm-9 py-8 pt-md-16 pb-md-14 d-md-none"
                color="#C9EFCD">
                <div>
                    <p class="subtitle-2 text-uppercase mb-4 font-weight-regular ls-1 lh-38">Our Motto</p>
                    <p class="secondary-fn fs-28 font-weight-regular">Our mission is to empower traders and <br> investors
                        to unleash their
                        potential by <br>supporting them with technology that is <br> sensible and easy-to-use.</p>
                </div>
                <v-divider class="my-6 mt-md-10 mb-md-6"></v-divider>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
                        <g clip-path="url(#clip0_1445_20190)">
                            <path
                                d="M8.58017 23H0.953353C0.426864 23 0 22.5527 0 22V12C0 6.95605 1.80197 3.61719 5.35563 2.07568C5.84208 1.86572 6.39929 2.10742 6.60132 2.61768C6.80242 3.12842 6.57106 3.71289 6.08461 3.92432C3.50246 5.04395 2.13899 7.36328 1.93417 11H8.58017C9.10666 11 9.53353 11.4478 9.53353 12V22C9.53353 22.5527 9.10666 23 8.58017 23Z"
                                fill="#212121" />
                            <path
                                d="M21.9273 23H14.3005C13.774 23 13.3472 22.5527 13.3472 22V12C13.3472 6.95605 15.1491 3.61719 18.7028 2.07568C19.1879 1.86572 19.7465 2.10742 19.9485 2.61767C20.1496 3.12841 19.9182 3.71288 19.4318 3.92431C16.8496 5.04395 15.4862 7.36328 15.2813 11H21.9273C22.4538 11 22.8807 11.4478 22.8807 12V22C22.8807 22.5527 22.4538 23 21.9273 23Z"
                                fill="#212121" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1445_20190">
                                <rect width="22.8805" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <p class="fs-20 lh-32 mb-0 mt-1"><span class="txt-444 font-weight-regular">Zebu was established to address the challenges faced by Indian traders in achieving financial growth.</span> </p>
                    <p class="fs-20 mb-0 mt-3">
                        <span class="font-weight-black">Vijayakumar, </span> <span class="font-weight-medium">Founder,
                            Zebuetrade</span>
                    </p>
                </div>
            </v-card>
        </div>
    </div>
</template>
  
<script>
export default {

    data: () => ({
        tickitems: [
            { img: require("@/assets/abt-zebu-tick1.svg"), tit: 'Innovative Technology', sub: 'Cutting-edge, intuitive trading tools for a seamless experience.' },
            { img: require("@/assets/abt-zebu-tick2.svg"), tit: 'Process-Driven Excellence', sub: 'Experience consistent, reliable, and high-quality trading services.' },
            { img: require("@/assets/abt-zebu-tick3.svg"), tit: 'Transparency You Can Trust', sub: 'Trust in our no-hidden-cost policy and commitment to deliver as promised.' },
            { img: require("@/assets/abt-zebu-tick4.svg"), tit: '360° Insight', sub: 'Stay ahead with holistic market insights and reliable service quality.' },
        ],
    }),
    mounted() {
    //   
    },
    methods: {
    //    
    }
}
</script>