<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-2 font-weight-bold secondary-infont fs-32 lh-32">Compound interest calculator</p>
                    <p class="title mb-1">Determine the future value of your investments with compounded growth over time.
          </p>
          <v-divider class="title mb-7"></v-divider>
                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Principle amount</p>
                            <v-text-field type="number" @keyup="keyUpchartupdation()" hide-spin-buttons dense
                                v-model="Principleammount" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>

                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Interest Rate (p.a.)</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="%" v-model="InterestRate"
                                        hide-spin-buttons min="1" max="20" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="InterestRate" ticks="always" tick-size="6"
                                        min="1" max="20" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>

                            <v-row class="my-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Tenure period</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="Yr" v-model="TenurePeriodYear"
                                        hide-spin-buttons min="1" max="50" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="TenurePeriodYear" ticks="always"
                                        tick-size="6" min="1" max="50" step="1" class='rounded-pill slider-range'
                                        track-color="#EEEEEE" thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>

                            <p class="mb-0 title font-weight-regular">Compounding frequency</p>
                            <v-select item-text="yearly" item-value="value" @change="chartupdation()" dense
                                v-model="freqSelect" :items="items" block flat solo background-color="#ffffff" c
                                class="calc-field elevation-0 rounded-pill mb-6" hide-details
                                append-icon="mdi-chevron-down">
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="mb-0 title font-weight-regular mt-6 mt-md-0">Estimation</p>
                            <v-row>
                                <v-col cols="12" md="7" class="py-4 px-8 px-md-4 mt-6">
                                    <v-card class="elevation-0 rounded-circle pb-2 px-1 brd-c-4" outlined>
                                        <canvas id="myChart"> </canvas>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-list-item class="pa-0 mb-8 mt-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#015FEC"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Loan
                                                Amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emireport ?
                                                emireport : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#1D1D1D"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                Interest</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiammount ?
                                                emiammount : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#6EB94B"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                Payment</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiinterest ?
                                                emiinterest : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="px-6 px-sm-8 px-md-10 py-6">
                    <p class="mb-0 font-weight-bold title">What is compound interest?</p>

                    <p class="mb-6 font-weight-light txt-444">Compound interest is the interest on a loan or deposit that
                        is calculated by adding the interest from the first payment to the interest from the previous
                        payments. It's a fancy way of saying "interest earned on money that was already earning interest."
                        This lets your money and interest grow faster than with simple interest, which is only calculated on
                        the principal amount.
                        <br><br>

                        The rate at which compound interest adds interest depends on how often it is used. The more times
                        compound interest is used, the more interest it will add. For example, if your interest rate is 10%
                        per year, putting away Rs 10000 would earn you Rs 1000 after a year. What will happen the next year?
                        This is where the interest on interest comes into play. You'll get interest on the money you put in,
                        and you'll also get interest on the interest you just got.
                        <br><br>

                        The longer you don't touch your money, the more it will grow. This is because compound interest
                        grows over time, which means that your money keeps growing over time. If you have a loan with
                        compound interest, you shouldn't forget to pay the interest, and if you pay the loan late, you'll
                        have to pay a lot of interest. To take advantage of compounding, you should try to pay off your loan
                        more often. In this way, you can pay less interest than you have to.
                        <br><br>
                        The interest-on-interest effect is sometimes called the "snowball effect" of compound interest
                        because it can lead to positive returns based on the initial principal amount.
                    </p>
                    <p class="mb-0 font-weight-bold title">What is compound interest calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">Compound interest is a way to make money from money you've
                        already put away. Before you can figure out compound interest, you need to know:
                        <br><br>
                        1. The amount of your main investment
                        <br><br>
                        2. The amount of interest your investor is willing to pay
                        <br><br>
                        3. The number of times per year that your interest is added up.
                        <br><br>
                        4. How long you want to keep your money in the stock
                        <br><br>
                        Once you have these numbers, it's easy to figure out how much you'll make from an investment that
                        uses the power of compounding interest.
                        <br><br>
                        This is the formula for compound interest: A = P (1+r/n)nt
                        <br><br>
                        A is the value of the investment in the future.
                        <br><br>
                        P = Amount invested in the first place
                        <br><br>
                        r = The interest rate (decimals)
                        <br><br>
                        n = Number of times per period that interest is added to the principal
                        <br><br>
                        t = The number of times that the money is invested
                    </p>
                    <p class="mb-0 font-weight-bold title">How to use the Compound Interest Calculator?</p>

                    <p class="mb-6 font-weight-light txt-444">Our compound interest calculator can help you figure out
                        what kind of interest rate you need. First, you need to know how much money you can put down right
                        away. Next, you can choose to add money to your investment on a regular basis if you want to. Type
                        in the amount you want to add and choose if the payments will be made monthly or yearly. Next,
                        decide how long you want your investment to last. Will you make the payments every month for 5, 10,
                        or 25 years? You can move the slider or just type the number of years into the box.
                        <br><br>
                        Once you're done putting money into your investment, you can choose to stay invested for a longer
                        time. This means that your interest will keep adding up, so your money will keep growing. It's
                        important that the number of years you choose to stay invested is more than the number of years you
                        want to invest for. Again, you can either move the slider or type the number directly into the box.
                        Check the graph on the right side of the page if you know how much money you want to have at the end
                        of the investment period. As you move the slider or type numbers into the box to change the interest
                        rate, you can see how much money you can expect to earn at the end of your investment term.
                        <br><br>
                        This will show you clearly what the interest rate is for you based on how much you can invest, how
                        long you want to invest, and how much money you want to have at the end of the investment.
                    </p>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
    data() {
        return {
            Principleammount: 10000,
            InterestRate: 10,
            TenurePeriodYear: 2,
            freqSelect: '1',
            items: [
                { yearly: 'Yearly', value: '1' },
                { yearly: 'Half Yearly', value: '2' },
                { yearly: 'Quarterly', value: '4' },
                { yearly: 'Monthly', value: '12' },
            ],

            emireport: 0,
            emiammount: 0,
            emiinterest: 0,

            doughnuts: [],
        }
    },

    mounted() {
        this.compCalculator();
        this.setChart();
    },

    methods: {
        keyUpchartupdation() {
            setTimeout(() => {
                this.compCalculator();
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        chartupdation() {
            this.compCalculator();
            setTimeout(() => {
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        compCalculator() {
            // console.log("emicalculator")
            var freqSelectt = this.freqSelect;
            var Principleammounts = parseInt(this.Principleammount);
            var InterestRates = (this.InterestRate) / (100);
            var TenurePeriodYears = parseInt(this.TenurePeriodYear);
            var compoundfrequency = parseInt(freqSelectt);
            var first = 1 + parseFloat(InterestRates) / (compoundfrequency)
            var second = compoundfrequency * TenurePeriodYears
            var third = Math.pow(first, second);
            var final = Principleammounts * third
            var emical = Math.round(final);
            var estval = Math.round(emical - Principleammounts);
            this.emireport = Principleammounts.toLocaleString();
            this.emiammount = estval.toLocaleString();
            this.emiinterest = emical.toLocaleString();
            this.doughnuts = [Principleammounts, estval];
        },
        setChart() {
            const setdoughnut = document.getElementById("myChart").getContext("2d");
            this.doughnut = new Chart(setdoughnut, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: this.doughnuts,
                        backgroundColor: ['#015FEC', '#1D1D1D'],
                        radius: "100%",
                        borderColor: ['#015FEC', '#1D1D1D'],
                        borderRadius: 0,
                        borderWidth: 0,
                        hoverOffset: 4,
                        borderAlign: 'center'
                    }]
                },

                options: {
                    cutout: "70%",
                    // rotation: -90,
                    // circumference: 180,
                    events: [],
                    title: {
                        // display: false,
                    },
                    legend: {
                        // display: false,
                    },
                    layout: {
                        padding: {
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }
                    },
                }
            });
        },
    }


}
</script>