<template>
    <div class="no-xhide">
        <div>
            <div data-aos="fade-up" class="pos-rlt pt-md-6 mb-md-16">
                <v-card class="elevation-0 px-16 py-10 rounded-xl pos-rlt d-none d-md-block" color="#E5EBEC">
                    <p class="fs-50 font-weight-bold mb-0">Zebu Blogs</p>
                    <div class="d-inline-flex mb-6">
                        <v-btn @click="activePage(i = p)" v-for="(p, b) in blogitems" :key="b" medium
                            :color="p.val === pagename.val ? '#000000' : '#666666'"
                            class="rounded-pill mr-2 text-none brd-2" outlined>
                            {{ p.txt }}
                        </v-btn>
                    </div>
                    <BlogSearch />

                    <div>
                        <img class="pos-abs calc-img text-right" alt="main-bg.png" src="@/assets/blog/main-bg.png"
                            width="42%" />
                    </div>
                    <div class="pos-abs learn-img">
                        <img alt="learn-bg.svg" src="@/assets/learn-bg.svg" width="172px" />
                    </div>
                </v-card>

                <v-card class="elevation-0 px-6 px-sm-8 py-10 rounded-0 text-center d-md-none" color="#E5EBEC">
                    <p class="fs-38 font-weight-bold mb-0">Zebu Blogs</p>
                    <div class="my-4">
                        <v-btn @click="activePage(i = p)" v-for="(p, b) in blogitems" :key="b" small
                            :color="p.val === pagename.val ? '#000000' : '#666666'"
                            class="rounded-pill text-none brd-2 mb-2 mr-2" outlined>
                            {{ p.txt }}
                        </v-btn>
                    </div>
                    <BlogSearch />
                </v-card>
            </div>

            <div class="pt-6 pb-16 px-0 px-md-14">
                <v-toolbar dense class="elevation-0 mb-6 d-none d-md-block">
                    <div>
                        <p class="mb-2 subtitle-2 font-weight-regular cursor-p"><span @click="toHome()">Zebu
                                Blogs</span> → {{ pagename.tit }}</p>

                        <p class="mb-0 font-weight-bold secondary-infont fs-32 lh-32">{{ pagename.tit }}</p>
                    </div>
                </v-toolbar>
                <v-toolbar dense class="elevation-0 mb-6 d-md-none">
                    <div class="pl-2 pl-sm-4">
                        <p class="mb-2 subtitle-2 font-weight-regular cursor-p"><span @click="toHome()">Zebu
                                Blogs</span> → {{ pagename.tit }}</p>

                        <p class="mb-0 font-weight-bold secondary-infont fs-28 lh-24">{{ pagename.tit }}</p>
                    </div>
                </v-toolbar>

                <div v-if="catblogs.length > 0" class="pl-4">
                    <v-row no-glutters class="pt-2 pt-md-6">
                        <v-col v-for="(b, l) in catblogs" :key="l" cols="12" sm="4" md="3" class="pb-0 pb-md-3">
                            <div>
                                <v-card data-aos="zoom-in-up" @click="singleBlog(b)" width="100%"
                                    class="elevation-0 hcard pl-2 pl-sm-4 pr-6 pr-sm-8 px-md-0">
                                    <v-card class="rounded-lg elevation-0">
                                        <img :src="b.attributes.img" width="100%"
                                            :alt="b.attributes.blog_image.data[0].attributes.url">
                                    </v-card>

                                    <v-list-item data-aos="fade-left" class="px-0">
                                        <v-list-item-content class="pt-1">
                                            <v-list-item-title class="font-weight-bold subtitle-1 text-rap-l2">{{
                                                b.attributes.blog_title
                                                }}</v-list-item-title>
                                            <p class="mb-0 subtitle-2 font-weight-regular txt-666666">{{
                                                b.attributes.publishedAt }}
                                            </p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <div v-else>
                    <v-container fill-height>
                        <v-card class="elevation-0 mx-auto my-16">
                            <v-progress-circular size="80" indeterminate color="#1e53e5"></v-progress-circular>
                        </v-card>
                    </v-container>
                </div>
                <v-container class="mt-6 mt-md-10">
                    <v-card v-if="catpagecount" data-aos="fade-down" max-width="600px"
                        class="box-s1 rounded-xl mx-auto py-1">
                        <v-pagination @input="activePage(i = pagename)" color="#000" prev-icon="mdi-arrow-left"
                            next-icon="mdi-arrow-right" v-model="page" class="my-3 elevation-0" :total-visible="11"
                            :length="catpagecount"></v-pagination>
                    </v-card>
                </v-container>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { strapi } from '../../apiurl'
import BlogSearch from '../Blogs/BlogSearch.vue'

export default {

    data: () => ({
        page: 1,
        pagecount: 0,
        catpagecount: 0,
        blogitems: [],

        pagename: {},

        allblogs: [],
        catblogs: [],

        detailblog: {},
        mainblog: {},
        mainside: [],
        mainslide: [],
    }),

    components: {
        BlogSearch
    },

    mounted() {
        var i = {
            tit: 'All'
        }
        this.activePage(i);
        this.getCategorie();
    },

    methods: {
        isContentVisible(b) {
            var schedule = b.attributes.schedule
            const scheduleDate = new Date(schedule);
            const currentDate = new Date();


            scheduleDate.setHours(0, 0, 0, 0);
            currentDate.setHours(0, 0, 0, 0);


            return scheduleDate <= currentDate;
        },

        scrollBehavior() {
            window.scrollTo(0, 0);
        },
        activePage(i) {
            this.catblogs = [];
            let urlis = null;
            if (i.tit === "All") {
                urlis = `${strapi}/blogs?sort[0]=blogdate:desc&pagination[page]=${this.page}&pagination[pageSize]=24&populate=*`;
            } else {
                urlis = `${strapi}/blogs?filters[blog_categories][name][$eq]=${i.tit}&sort[0]=blogdate:desc&pagination[page]=${this.page}&pagination[pageSize]=24&populate[blog_image]=*`;
            }
            let cconfig = {
                method: 'get',
                url: urlis,
            };
            let catdata = [];
            let axiosThis = this;
            axios.request(cconfig)
                .then((response) => {
                    if (response.data.data) {
                        let datas = response.data.data
                        // for (let i = 0; i < datas.length; i++) {
                        //     datas[i].attributes['img'] = `${strapi}${datas[i].attributes.blog_image.data[0].attributes.url}`;
                        //     datas[i].attributes.publishedAt = `${new Date(datas[i].attributes.publishedAt).toDateString().slice(3)} ${new Date(datas[i].attributes.publishedAt).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                        //     catdata.push(datas[i]);
                        // }

                        for (let i = 0; i < datas.length; i++) {
                            if (datas[i].attributes.schedule) {
                                const scheduleDate = new Date(datas[i].attributes.schedule);
                                const currentDate = new Date();
                                scheduleDate.setHours(0, 0, 0, 0);
                                currentDate.setHours(0, 0, 0, 0);
                                if (scheduleDate <= currentDate) {
                                    datas[i].attributes['img'] = `${strapi}${datas[i].attributes.blog_image.data[0].attributes.url}`;
                                    datas[i].attributes.publishedAt = `${new Date(datas[i].attributes.publishedAt).toDateString().slice(3)} ${new Date(datas[i].attributes.publishedAt).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                                    catdata.push(datas[i]);
                                }
                            }
                            else {
                                datas[i].attributes['img'] = `${strapi}${datas[i].attributes.blog_image.data[0].attributes.url}`;
                                datas[i].attributes.publishedAt = `${new Date(datas[i].attributes.publishedAt).toDateString().slice(3)} ${new Date(datas[i].attributes.publishedAt).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                                catdata.push(datas[i]);
                            }
                        }





                    } else {
                        catdata = []
                    }
                    axiosThis.catblogs = catdata;
                    axiosThis.catpagecount = response.data.meta.pagination.pageCount;
                })
                .catch((error) => {
                    console.log(error);
                });
            this.pagename = i;
            this.scrollBehavior();
        },
        getCategorie() {
            this.blogitems = [];
            this.blogitems = [{ tit: 'All', txt: 'All', btn: 'Show all blogs', val: 'all', id: 1 }]
            let config = {
                method: 'get',
                url: `${strapi}/blog-categories`,
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    if (response.data.data) {
                        let data = response.data.data;
                        for (let c = 0; c < data.length; c++) {
                            let cconfig = {
                                method: 'get',
                                url: `${strapi}/blogs?filters[blog_categories][name][$eq]=${data[c].attributes.name}&sort[0]=blogdate:desc&pagination[pageSize]=${4}&populate[blog_image]=*`,
                            };
                            let catdata = [];
                            axios.request(cconfig)
                                .then((response) => {
                                    if (response.data.data) {
                                        let datas = response.data.data


                                        for (let i in datas) {
                                            // console.log("CATTT", datas[i].attributes.schedule);
                                            if (datas[i].attributes.schedule) {
                                                // console.log("vvvv", datas[i].attributes.schedule);

                                                const scheduleDate = new Date(datas[i].attributes.schedule);
                                                const currentDate = new Date();
                                                scheduleDate.setHours(0, 0, 0, 0);
                                                currentDate.setHours(0, 0, 0, 0);
                                                if (scheduleDate <= currentDate) {
                                                    datas[i].attributes['img'] = `${strapi}${datas[i].attributes.blog_image.datas[0].attributes.url}`;
                                                    datas[i].attributes.publishedAt = `${new Date(datas[i].attributes.publishedAt).toDateString().slice(3)} ${new Date(datas[i].attributes.publishedAt).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                                                    axiosThis.allblogs.push(datas[i]);
                                                }
                                            }
                                            else {
                                                axiosThis.allblogs.push(datas[i]);
                                            }

                                        }
                                    } else {
                                        catdata = []
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                            axiosThis.blogitems.push({ tit: data[c].attributes.name, txt: data[c].attributes.name.split(' ', 1)[0], btn: `More ${data[c].attributes.name} blog`, val: data[c].attributes.name.slice(0, 3), id: c + 2, data: catdata })
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getBlogdata() {
            this.allblogs = [];

            let config = {
                method: 'get',
                url: `${strapi}/blogs?sort[0]=blogdate:desc&pagination[page]=${this.page}&pagination[pageSize]=24&populate=*`,
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    if (response.data.data) {
                        let data = response.data.data;
                        // console.log("vvvsssssv");

                        for (let i = 0; i < data.length; i++) {
                            if (data[i].attributes.schedule) {
                                // console.log("vvvv", data[i].attributes.schedule);

                                const scheduleDate = new Date(data[i].attributes.schedule);
                                const currentDate = new Date();
                                scheduleDate.setHours(0, 0, 0, 0);
                                currentDate.setHours(0, 0, 0, 0);
                                if (scheduleDate <= currentDate) {
                                    data[i].attributes['img'] = `${strapi}${data[i].attributes.blog_image.data[0].attributes.url}`;
                                    data[i].attributes.publishedAt = `${new Date(data[i].attributes.publishedAt).toDateString().slice(3)} ${new Date(data[i].attributes.publishedAt).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                                    axiosThis.allblogs.push(data[i]);
                                }
                            }
                            else {
                                axiosThis.allblogs.push(data[i]);
                            }

                        }
                        axiosThis.mainblog = axiosThis.allblogs[0];
                        axiosThis.mainside = axiosThis.allblogs.slice(1, 5);
                        axiosThis.mainslide = axiosThis.allblogs.slice(5, 9);
                        let index = axiosThis.blogitems.findIndex(x => x.tit === "All");
                        axiosThis.blogitems[index]['data'] = axiosThis.allblogs.slice(9, 13);
                        axiosThis.pagecount = response.data.meta.pagination.pageCount;
                        axiosThis.scrollBehavior();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        },

        singleBlog(b, p) {
            if (p) {
                //  
            }
            else {
                p = this.blogitems.find(o => o.tit === 'All');
            }
            delete p.data;

            b.attributes['pagename'] = p;
            const formattedTitle = b.attributes.blog_title.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '');
            this.$router.push(`/blog/${formattedTitle}-id${b.id}`);

        },
        catBlog() {
            var item = this.blogitems.find(o => o.tit === this.pagename.tit);
            if (item == undefined) {
                this.pagename = this.blogitems.find(o => o.tit === 'All');
            } else {
                this.pagename = item;
            }
            var i = this.pagename;
            this.activePage(i)

        },
        toHome() {
            this.$router.push('/blogs');
        }

    }
}
</script>