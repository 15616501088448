<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-2 font-weight-bold secondary-infont fs-32 lh-32">SIP calculator</p>
                    <p class="title mb-1">Evaluate the returns from your Systematic Investment Plan (SIP) over a specified period.
          </p>
          <v-divider class="title mb-7"></v-divider>
                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Principal amount</p>
                            <v-text-field type="number" @keyup="keyUpchartupdation()" hide-spin-buttons dense
                                v-model="Monthlyinvestment" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>
                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Interest Rate (p.a.)</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="%" v-model="interestrate"
                                        hide-spin-buttons min="1" max="20" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="interestrate" ticks="always" tick-size="6"
                                        min="1" max="20" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>
                                </v-col>
                            </v-row>

                            <v-row class="my-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Tenure period</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="Yr" v-model="tenureperiod"
                                        hide-spin-buttons min="1" max="50" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="tenureperiod" ticks="always" tick-size="6"
                                        min="1" max="50" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="mb-0 title font-weight-regular">Estimation</p>
                            <v-row>
                                <v-col cols="12" md="7" class="py-4 px-8 px-md-4 mt-6">
                                    <v-card class="elevation-0 rounded-circle pb-2 px-1 brd-c-4" outlined>
                                        <canvas id="myChart"> </canvas>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-list-item class="pa-0 mb-8 mt-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#015FEC"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Principal Amount
                                            </v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ Investedamount ?
                                                Investedamount : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#1D1D1D"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                Interest</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ Returns ?
                                                Returns : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#6EB94B"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                Amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ totalamount ?
                                                totalamount : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="px-6 px-sm-8 px-md-10 py-6">
                    <p class="mb-0 font-weight-bold title">What is SIP?</p>
                    <p class="mb-6 font-weight-light txt-444">A systematic Investment Plan, or SIP, is a way to invest
                        regular, fixed amounts in the Mutual Fund scheme of your choice. Every month, a set amount is taken
                        out of your savings account and put into the Mutual Fund of your choice. </p>
                    <p class="mb-0 font-weight-bold title">Advantages of SIP investment?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        Easy to make a decision<br>
                        With SIP, you can start investing as little as Rs 500 per month and watch your money grow. A SIP is
                        simple and easy to keep track of, and it also helps you save more money.<br><br>
                        Rupee Cost Averaging: The Rupee Cost Averaging feature is what makes SIP special. With this feature,
                        you buy more units when the market is low and less when the market is high. This is because SIP is
                        designed so that every time the market goes down, you buy more, so your cost of investment goes down
                        and your gains go up.<br><br>
                        Flexibility: SIP gives you a lot of freedom to do what you want. With SIP, you can avoid making
                        long-term commitments like putting money into a Public Provident Fund or a Unit-Linked Insurance
                        Plan. These are funds that have no fixed end date, so you can take them out whenever you want. You
                        can take out the whole amount or just a part of it from your investment without losing any money.
                        The amount of money invested can also be changed. It can go up or down. Just remember to invest for
                        a long time.<br><br>
                        Higher returns: SIP gives twice as much money back as traditional fixed deposits or recurring
                        deposits. This can help you get around the high prices.<br><br>
                        Power of compounding: SIP is based on the idea that your investments will earn you interest on
                        interest. In other words, if you invest a small amount for a long time, you will get a better return
                        than if you invest a huge amount just once.<br><br>
                        Acts as an emergency fund: Since SIP Investment is an open-ended fund with no end date, you can use
                        it as an emergency fund.
                    </p>
                    <p class="mb-0 font-weight-bold title">Types of SIP investment?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        Fund houses have created different kinds of SIPs to meet the needs of all types of investors. Here
                        are some popular choices:<br><br>
                        Flexible SIPs<br>
                        It's also called Flex SIP or Flexi SIP. It lets you change the SIP amount based on how much money
                        you have and how the market is doing. There is a set formula for how the market works, so investors
                        can invest more when the market is going down and choose a lower SIP amount when the market is going
                        up.<br><br>
                        In the same way, if money is tight, you can decrease the SIP amount and increase it when you have
                        more money. When it comes to flexible SIPs, the investor can change the amount as needed.<br><br>
                        Step-Up SIP<br>
                        Boost or step-up SIP lets you increase the amount you put in at set times. For example, you could
                        start investing in a mutual fund scheme of your choice with Rs. 10,000 and tell the fund house to
                        increase the SIP by Rs. 500 every six months.<br><br>
                        As you asked, the SIP amount will go up to Rs. 10,500/month after the first six months of investing
                        Rs. 10,000/month. From the 13th month on, it will go up by Rs. 500/month again. Step-up SIP can be a
                        great choice for salaried workers who are about to get a raise.<br><br>
                        Perpetual SIP<br>
                        Permanent SIP is one of the good systematic investment options because it is connected to every SIP
                        investor. The SIP mandate says that when you start a SIP, you have to give a start date and an end
                        date. Many investors fill in the date they started, but few fill in the date they will stop.<br><br>
                        If there is no end date in the mandate, the SIP is considered to last until 2099 and is called a
                        "perpetual SIP." But you can stop the SIP at any time by sending a written request to the fund
                        house. If you only want to invest for a certain amount of time, make sure to enter the end date for
                        your SIP.<br><br>
                        Trigger SIP<br>
                        You can set a trigger for your SIP investment with the Trigger SIP. For example, you can say that
                        your SIP amount should only be taken out of your bank account and used to buy units of the chosen
                        scheme if the scheme's Net Asset Value (NAV) falls below a certain level.<br><br>
                        You can also set triggers based on dates or levels of an index like the Nifty or Sensex. But this
                        option is only recommended for experienced investors who know how to set these triggers well and
                        have done it before.
                    </p>
                    <p class="mb-0 font-weight-bold title">What is an SIP Calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        Figure out how much you need to save or how much your SIP will help you build up.<br><br>
                        A SIP calculator is a simple tool that helps people figure out how much their SIP investments in
                        mutual funds will earn them.<br><br>
                        This mutual fund sip calculator is made to help people who want to invest in mutual funds get an
                        idea of how much they will have to invest. But the actual returns that a mutual fund scheme gives
                        vary depending on many factors. The SIP calculator doesn't explain what the exit load and expense
                        ratio are (if any).<br><br>
                        Based on a projected annual return rate, you can get a rough idea of how much you will have at the
                        end of any of your monthly SIPs.<br><br>
                        The formula for a SIP plan calculator is M = P ([1 + i]n - 1 / I (1 + I))<br><br>
                        M is the amount you get at maturity<br>
                        P is the amount you invest regularly<br>
                        n is the number of payments<br>
                        I is the rate of interest<br>
                    </p>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
    data() {
        return {
            Monthlyinvestment: 10000,
            interestrate: 10,
            tenureperiod: 2,
            totalamount: 0,
            Investedamount: 0,
            Returns: 0,
            estval: 0,

            doughnuts: [],
        }
    },

    mounted() {
        this.compCalculator();
        this.setChart();
    },

    methods: {
        keyUpchartupdation() {
            setTimeout(() => {
                this.compCalculator();
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        chartupdation() {
            this.compCalculator();
            setTimeout(() => {
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        compCalculator() {
            var Principleammount = parseInt(this.Monthlyinvestment)
            var InterestRate = parseFloat(this.interestrate) / (100 * 12);
            var TenurePeriodYear = (this.tenureperiod) * 12;
            if ((Principleammount > 0 && InterestRate > 0 && (TenurePeriodYear > 0)) && (Principleammount <= Math.pow(10, 9) && InterestRate <= 50 / (100 * 12) && (TenurePeriodYear <= 50 * 12))) {
                var first = 1 + InterestRate
                var second = Math.pow(first, TenurePeriodYear) - 1;
                var third = second / InterestRate
                var fourth = 1 + InterestRate;
                var cofinal = third * fourth
                var final = cofinal * Principleammount
                var emical = Math.round(final);
                var tinv = Principleammount * TenurePeriodYear
                this.estval = Math.round(final - tinv);
                this.totalamount = emical.toLocaleString();
                this.Investedamount = (Principleammount * TenurePeriodYear).toLocaleString();
                this.Returns = this.estval.toLocaleString();
            }
            this.doughnuts = [(Principleammount * TenurePeriodYear), this.estval];
        },
        setChart() {
            const setdoughnut = document.getElementById("myChart").getContext("2d");
            this.doughnut = new Chart(setdoughnut, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: this.doughnuts,
                        backgroundColor: ['#015FEC', '#1D1D1D'],
                        radius: "100%",
                        borderColor: ['#015FEC', '#1D1D1D'],
                        borderRadius: 0,
                        borderWidth: 0,
                        hoverOffset: 4,
                        borderAlign: 'center'
                    }]
                },

                options: {
                    cutout: "70%",
                    // rotation: -90,
                    // circumference: 180,
                    events: [],
                    title: {
                        // display: false,
                    },
                    legend: {
                        // display: false,
                    },
                    layout: {
                        padding: {
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }
                    },
                }
            });
        },
    }


}
</script>