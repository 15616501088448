<template>
    <div>
        <img class="rounded-0 hero-pg-img" src="https://zebuetrade.com/asset/static/img/apppromo.png" width="100%">
    </div>
</template>

<script>
export default {

    data: () => ({

    }),
    mounted() {
        var styleElement = document.createElement("style");
        styleElement.appendChild(document.createTextNode("::-webkit-scrollbar {width: 0px; height:0px !important;} .banners {background-color: #2a2a2a !important}"));
        document.getElementsByTagName("head")[0].appendChild(styleElement);
    }
}
</script>