<template>
    <div>
        <div class="d-none d-md-block">
            <v-autocomplete @change="singleBlog(b = blogis)" item-text="attributes.blog_title" item-value="attributes"
            append-icon="" :items="catblogs" :disabled="isLoading" :loading="isLoading" v-model="blogis" block flat solo
            background-color="#ffffff" clearable return-object
            class="search-field elevation-0 mr-3 rounded-pill" label="Search news articles" hide-details>
            <template #prepend-inner>
                <img src="@/assets/search-icon.svg" width="100%" class="mx-2" alt="search-icon">
            </template>
        </v-autocomplete>
        </div>
        <div class="d-md-none">
            <v-autocomplete @change="singleBlog(b = blogis)" item-text="attributes.blog_title" item-value="attributes"
            append-icon="" :items="catblogs" :disabled="isLoading" :loading="isLoading" v-model="blogis" block flat solo
            background-color="#ffffff" clearable return-object
            class="elevation-0 rounded-pill" label="Search news articles" hide-details>
            <template #prepend-inner>
                <img src="@/assets/search-icon.svg" width="100%" class="mx-2" alt="search-icon">
            </template>
        </v-autocomplete>
        </div>
    </div>
</template>
  
<script>
import axios from "axios";
import { strapi } from '../../apiurl'

export default {

    data: () => ({
        page: 1,
        pagecount: 0,
        catpagecount: 0,
        blogitems: [],

        pagename: {},

        allblogs: [],
        catblogs: [],

        detailblog: {},
        mainblog: {},
        mainside: [],
        mainslide: [],

        blogis: null,
        isLoading: null,
    }),

    mounted() {
        this.activePage();
    },

    methods: {
        activePage() {
            this.isLoading = true;
            this.catblogs = [];

            let cconfig = {
                method: 'get',
                url: `${strapi}/blogs?sort[0]=blogdate:desc&pagination[page]=${this.page}&pagination[pageSize]=24&populate=*`,
            };
            let catdata = [];
            let axiosThis = this;
            axios.request(cconfig)
                .then((response) => {
                    if (response.data.data) {
                        let datas = response.data.data
                        // for (let i = 0; i < datas.length; i++) {
                        //     datas[i].attributes['img'] = `${strapi}${datas[i].attributes.blog_image.data[0].attributes.url}`;
                        //     datas[i].attributes.publishedAt = `${new Date(datas[i].attributes.publishedAt).toDateString().slice(3)} ${new Date(datas[i].attributes.publishedAt).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                        //     catdata.push(datas[i]);
                        // }

                        for (let i = 0; i < datas.length; i++) {
                            if (datas[i].attributes.schedule) {
                                const scheduleDate = new Date(datas[i].attributes.schedule);
                                const currentDate = new Date();
                                scheduleDate.setHours(0, 0, 0, 0);
                                currentDate.setHours(0, 0, 0, 0);
                                if (scheduleDate <= currentDate) {
                                    datas[i].attributes['img'] = `${strapi}${datas[i].attributes.blog_image.data[0].attributes.url}`;
                            datas[i].attributes.publishedAt = `${new Date(datas[i].attributes.publishedAt).toDateString().slice(3)} ${new Date(datas[i].attributes.publishedAt).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                            catdata.push(datas[i]);
                                }
                            }
                            else {
                                datas[i].attributes['img'] = `${strapi}${datas[i].attributes.blog_image.data[0].attributes.url}`;
                            datas[i].attributes.publishedAt = `${new Date(datas[i].attributes.publishedAt).toDateString().slice(3)} ${new Date(datas[i].attributes.publishedAt).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                            catdata.push(datas[i]);
                            }
                        }

                    } else {
                        catdata = []
                    }
                    axiosThis.isLoading = false;
                    axiosThis.catblogs = catdata;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        singleBlog(b) {
            if (b) {
                if (location.pathname == '/blog') {
                    b.attributes['pagename'] = this.pagename;
                    localStorage.setItem("50bf", JSON.stringify(b));
                    this.$emit("setParentComponentDetails");
                }
                else {
                    // b.attributes['pagename'] = this.pagename;
                    // this.$router.push({ name: 'single blog', params: b });
                    b.attributes['pagename'] = b;
      const formattedTitle = b.attributes.blog_title.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '');
      this.$router.push(`/blog/${formattedTitle}-id${b.id}`);
                }
            }
        },
    }
}
</script>