<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-2 font-weight-bold secondary-infont fs-32 lh-32">Simple Interest calculator</p>
                    <p class="title mb-1">Calculate the simple interest earned or owed based on principal, rate, and time.
          </p>
          <v-divider class="title mb-7"></v-divider>
                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Principal amount</p>
                            <v-text-field type="number" @keyup="keyUpchartupdation()" hide-spin-buttons dense
                                v-model="Monthlyinvestment" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>
                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Interest Rate (p.a.)</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="%" v-model="interestrate"
                                        hide-spin-buttons min="1" max="20" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="interestrate" ticks="always" tick-size="6"
                                        min="1" max="20" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>
                                </v-col>
                            </v-row>

                            <v-row class="my-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Tenure period</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="Yr" v-model="tenureperiod"
                                        hide-spin-buttons min="1" max="50" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="tenureperiod" ticks="always" tick-size="6"
                                        min="1" max="50" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="mb-0 title font-weight-regular">Estimation</p>
                            <v-row>
                                <v-col cols="12" md="7" class="py-4 px-8 px-md-4 mt-6">
                                    <v-card class="elevation-0 rounded-circle pb-2 px-1 brd-c-4" outlined>
                                        <canvas id="myChart"> </canvas>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-list-item class="pa-0 mb-8 mt-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#015FEC"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Principal Amount
                                            </v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiamount ?
                                                emiamount : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#1D1D1D"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                Interest</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ interst ?
                                                interst : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#6EB94B"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                Amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emitotal ?
                                                emitotal : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="px-6 px-sm-8 px-md-10 py-6">

                    <p class="mb-0 font-weight-bold title">How does simple interest work?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        Simple interest is the rate at which you borrow or lend money. If someone borrows money from a
                        lender, they have to pay back more money than they borrowed. The money you borrow for a certain
                        amount of time is called the "principal." Interest is the extra amount that is paid back to the
                        lender for using the money.
                        <br><br>
                        Multiply the principal, number of periods, and interest rate to get simple interest. Simple interest
                        doesn't build up, and you don't have to pay interest on interest. In simple interest, the payment
                        goes toward the interest for the month, and the rest of the payment goes toward paying down the
                        principal.
                    </p>
                    <p class="mb-0 font-weight-bold title">What is a simple interest calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        A simple interest calculator is a useful tool that works without compounding to figure out the
                        interest on a loan or savings. On a daily, monthly, or yearly basis, you can figure out the simple
                        interest on the principal amount. Enter the principle amount, annual rate, and duration in days,
                        months, or years in the simple interest calculator's formula box. The interest on the loan or the
                        investment will be shown on the calculator.
                    </p>
                    <p class="mb-0 font-weight-bold title">How does the simple interest calculator work?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        The simple interest calculator will show the total amount of principal and interest that has been
                        added up. The math formula used by the simple interest calculator is:
                        <br><br>
                        A = P (1+rt)
                        <br><br>
                        P = The main amount
                        <br><br>
                        R = Interest rate
                        <br><br>
                        t = Amount of time
                        <br><br>
                        A = Total amount owed (Both principal and the interest)
                        <br><br>
                        Interest is A minus P.
                        <br><br>
                        Let's use an example to see how the simple interest calculator works so we can understand how it
                        works. The main loan amount is Rs 10,000, the interest rate is 10%, and the loan term is six years.
                        To figure out the simple interest, you can:
                        <br><br>
                        A = 10,000 (1+0.1*6) = Rs 16,000.
                        <br><br>
                        Interest = A - P = Rs 16000 - Rs 10000 = Rs 6000.
                    </p>
                    <p class="mb-0 font-weight-bold title">How Do I Use the Zebu Simple Interest Calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">
                        The Zebu Simple Interest Calculator tells you how much simple interest you have made on any
                        deposits. For the simple interest calculator to work:
                        <br><br>
                        You must choose "simple interest" as the type of interest.
                        <br><br>
                        You put in the amount of the loan.
                        <br><br>
                        Then you put in the interest rate per year.
                        <br><br>
                        You have to pick the length of time in terms of days, weeks, quarters, or years.
                        <br><br>
                        The Zebu Simple Interest Calculator will show you how much simple interest you have made on your
                        deposit.
                    </p>
                    <p class="mb-0 font-weight-bold title">Why Should You Use Zebu's Simple Interest Calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">The Zebu Simple Interest Calculator tells you in seconds
                        how much simple interest you're making on your deposit. You can figure out how much interest you pay
                        on any loan by comparing the simple interest rates to the compound interest rates.
                    </p>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
    data() {
        return {
            Monthlyinvestment: 200000,
            interestrate: 6,
            tenureperiod: 5,
            emiamount: 0,
            interst: 0,
            emitotal: 0,

            doughnuts: [],
        }
    },

    mounted() {
        this.compCalculator();
        this.setChart();
    },

    methods: {
        keyUpchartupdation() {
            setTimeout(() => {
                this.compCalculator();
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        chartupdation() {
            this.compCalculator();
            setTimeout(() => {
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        compCalculator() {
            var Principleammount = this.Monthlyinvestment;
            var InterestRate = this.interestrate / 100;
            var TenurePeriodYear = this.tenureperiod;
            if (
                Principleammount > 0 &&
                InterestRate > 0 &&
                TenurePeriodYear > 0 &&
                Principleammount <= Math.pow(10, 10) &&
                InterestRate <= 50 / 100 &&
                TenurePeriodYear <= 50
            ) {
                var amtcal = Math.round([
                    parseFloat(Principleammount) *
                    (1 + parseFloat(TenurePeriodYear) * parseFloat(InterestRate)),
                ]);
                this.emiamount = Principleammount.toLocaleString();
                var SIintrest = parseFloat(amtcal) - parseFloat(Principleammount);
                this.interst = SIintrest.toLocaleString();
                this.emitotal = parseFloat(amtcal).toLocaleString();
            }
            this.doughnuts = [Principleammount, SIintrest];
        },
        setChart() {
            const setdoughnut = document.getElementById("myChart").getContext("2d");
            this.doughnut = new Chart(setdoughnut, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: this.doughnuts,
                        backgroundColor: ['#015FEC', '#1D1D1D'],
                        radius: "100%",
                        borderColor: ['#015FEC', '#1D1D1D'],
                        borderRadius: 0,
                        borderWidth: 0,
                        hoverOffset: 4,
                        borderAlign: 'center'
                    }]
                },

                options: {
                    cutout: "70%",
                    // rotation: -90,
                    // circumference: 180,
                    events: [],
                    title: {
                        // display: false,
                    },
                    legend: {
                        // display: false,
                    },
                    layout: {
                        padding: {
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }
                    },
                }
            });
        },
    }


}
</script>