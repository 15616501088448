<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-2 font-weight-bold secondary-infont fs-32 lh-32">EMI Calculator</p>
                    <p class="title mb-1">Compute your monthly loan payments and plan finances effectively with this EMI tool.
          </p>
          <v-divider class="title mb-7"></v-divider>
                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Principal amount</p>
                            <v-text-field type="number" @keyup="keyUpchartupdation()" hide-spin-buttons dense
                                v-model="Principleammounts" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>

                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Interest Rate (p.a.)</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="%" v-model="InterestRate"
                                        hide-spin-buttons min="1" max="20" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="InterestRate" ticks="always" tick-size="6"
                                        min="1" max="20" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>

                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Tenure period</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="Yr" v-model="TenurePeriodYear"
                                        hide-spin-buttons min="1" max="50" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="TenurePeriodYear" ticks="always"
                                        tick-size="6" min="1" max="50" step="1" class='rounded-pill slider-range'
                                        track-color="#EEEEEE" thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="mb-0 title font-weight-regular">Estimation</p>
                            <v-row>
                                <v-col cols="12" md="7" class="py-4 px-8 px-md-4 mt-6">
                                    <v-card class="elevation-0 rounded-circle pb-2 px-1 brd-c-4" outlined>
                                        <canvas id="myChart"> </canvas>
                                    </v-card>

                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#AAAAAA"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Monthly
                                                EMI</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emicalreport ?
                                                emicalreport : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#015FEC"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Principal
                                                amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emicalemiammount ?
                                                emicalemiammount : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#1D1D1D"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                Interest</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiinterest ?
                                                emiinterest : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#6EB94B"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                Payment</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emitotal ?
                                                emitotal : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="px-6 px-sm-8 px-md-10 py-6">
                    <p class="mb-0 font-weight-bold title">What is EMI?</p>

                    <p class="mb-6 font-weight-light txt-444">Equated Monthly installment, or EMI for short, is the
                        amount owed to the bank or other financial institution each month until the loan is paid off in
                        full. It includes both the loan's interest and a part of the principal that needs to be paid back.
                        By dividing the sum of the principal and interest, you can figure out how many months it will take
                        to pay back the loan. This amount needs to be paid off each month. At first, the interest part of
                        the EMI would be huger, but it would get smaller with each payment after that. The interest rate
                        determines how much of the payment goes toward paying off the loan's principal. The amount of
                        principal and interest you pay each month won't change, but it will change over time. With each
                        payment, you'll put more money toward the principal and less toward the interest.
                        <br><br>

                        Using the above EMI formula by hand or in MS Excel to figure out EMI for different combinations of
                        principal loan amount, interest rate, and length of loan takes time, is hard, and can lead to
                        mistakes. With the help of our EMI calculator, you can automate this calculation and get the results
                        right away, along with graphs showing the total payment breakdown and the payment schedule.
                    </p>
                    <p class="mb-0 font-weight-bold title">How do you use the EMI Calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">Our EMI Calculator is quick, easy to use, and easy to
                        understand. With this calculator, you can figure out the EMI for a home loan, car loan, personal
                        loan, education loan, or any other fully amortising loan.
                        <br><br>

                        Fill in the following into the EMI Calculator:
                        <br><br>

                        Principal loan amount you desire to avail (rupees)
                        Loan period (months or years)
                        Rates of interest (percentage)
                        <br><br>

                        Use the slider on the EMI calculator form to change the settings. If you need to give more accurate
                        values, you can just type them in the spaces provided above. As soon as you change the settings with
                        the slider, the EMI calculator will give you a new monthly payment amount (or after putting the
                        values straight into the input fields by pressing the "tab" key).
                        <br><br>

                        There is also a pie chart that shows how the whole payment was made (total principle vs. total
                        interest payable). As a percentage, the total of all loan payments shows the amount of interest paid
                        compared to the amount of the loan. Along with a chart showing how much interest and principal are
                        paid each year, a table showing how much is paid each month or year for the length of the loan is
                        also shown. Each payment includes a part that goes toward the principal balance and a part that goes
                        toward the interest. During the first part of the loan, interest is a huge part of every payment. The
                        payment plan also shows how much of each year's unpaid balance will be carried over to the next
                        year.
                    </p>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
    data() {
        return {
            Principleammounts: 200000,
            InterestRate: 8,
            TenurePeriodYear: 2,
            emicalreport: 0,
            emicalemiammount: 0,
            emiintrestdisplay: 0,
            emiinterest: 0,
            emitotal: 0,

            doughnuts: [],
        }
    },

    mounted() {
        this.emiCalculator();
        this.setChart();
    },

    methods: {
        keyUpchartupdation() {
            setTimeout(() => {
                this.emiCalculator();
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        chartupdation() {
            this.emiCalculator();
            setTimeout(() => {
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        emiCalculator() {
            var Principleammount = this.Principleammounts;
            this.emicalemiammount = parseInt(Principleammount)
            var InterestRates = this.InterestRate / (100 * 12); ``
            var TenurePeriodYears = this.TenurePeriodYear * 12;
            // console.log("count", Principleammount, InterestRates, TenurePeriodYears)
            if ((Principleammount > 0 && InterestRates > 0 && (TenurePeriodYears > 0)) && (Principleammount <= Math.pow(10, 9) && InterestRates <= 50 / (100 * 12) && (TenurePeriodYears <= 50 * 12))) {
                var emical = Math.round(([(parseFloat(Principleammount) * parseFloat(InterestRates) * Math.pow(1 + parseFloat(InterestRates), parseFloat(TenurePeriodYears)))] / [(Math.pow((1 + parseFloat(InterestRates)), (parseFloat(TenurePeriodYears))) - 1)]))
                this.emicalreport = (emical.toLocaleString())
                var emiintrest = (parseFloat(emical) * parseFloat(TenurePeriodYears)) - parseFloat(Principleammount);
                this.emiintrestdisplay = parseInt(emiintrest)
                var emitotal = parseInt(Principleammount) + parseInt(emiintrest);
                this.emiinterest = emiintrest.toLocaleString();
                this.emitotal = emitotal.toLocaleString();
            }
            this.doughnuts = [Principleammount, emiintrest];
        },
        setChart() {
            const setdoughnut = document.getElementById("myChart").getContext("2d");
            this.doughnut = new Chart(setdoughnut, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: this.doughnuts,
                        backgroundColor: ['#015FEC', '#1D1D1D'],
                        radius: "100%",
                        borderColor: ['#015FEC', '#1D1D1D'],
                        borderRadius: 0,
                        borderWidth: 0,
                        hoverOffset: 4,
                        borderAlign: 'center'
                    }]
                },

                options: {
                    cutout: "70%",
                    // rotation: -90,
                    // circumference: 180,
                    events: [],
                    title: {
                        // display: false,
                    },
                    legend: {
                        // display: false,
                    },
                    layout: {
                        padding: {
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }
                    },
                }
            });
        },
    }


}
</script>