<template>
    <div>
        <div>
            <div class="mt-10">
                <v-toolbar dense class="elevation-0 mb-6 d-none d-md-block">
                    <div>
                        <p class="mb-0 font-weight-bold secondary-infont fs-32 lh-32">Our playlists</p>
                        <p class="mb-0 title font-weight-regular">A well-organized collection of all our videos, tailored to meet your learning needs.</p>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn to="/playlists" color="#0037B7" text class="text-capitalize"><span class="font-weight-medium">All
                            playlists</span></v-btn>
                </v-toolbar>
                <v-toolbar dense class="elevation-0 mb-6 d-md-none ">
                    <div class="pl-2 pl-sm-4">
                        <p class="mb-0 font-weight-bold secondary-infont fs-28">Our playlists</p>
                        <p class="mb-0 fs-16 font-weight-regular">A well-organized collection of all our videos, tailored to meet your learning needs.</p>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn to="/playlists" color="#0037B7" text class="text-capitalize"><span
                            class="font-weight-medium fs-14">All
                            playlists</span></v-btn>
                </v-toolbar>
                <div v-if="homeplaylist.length > 0" class="pl-6 pl-sm-8 pl-md-4 pt-2 pt-md-6 overflow-x-hidden">
                    <v-card width="100%" v-dragscroll.x
                        class="d-inline-flex overflow-x-auto my-4 no-scroll rounded-lg elevation-0" color="transparent">
                        <v-card data-aos="zoom-in-up" v-for="(v, l) in homeplaylist" :key="l" @click="playPlaylist(v)"
                            width="100%" class="elevation-0 hcard" :class="l != homeplaylist.length - 1 ? 'mr-4' : 'mr-0'">
                            <div class="pos-rlt">
                                <v-card class="rounded-lg elevation-0" color="transparent">
                                    <img :src="v.thumbnailsURL" width="300px" :alt="v.thumbnailsURL">
                                </v-card>
                                <v-card color="#ffffff" class="elevation-0 rounded-md py-1 px-3 pos-abs abs-post1">
                                    <span class="font-weight-black subtitle-2">{{ v.videoCount }} VIDEOS</span>
                                </v-card>
                            </div>
                            <v-list-item data-aos="fade-left" class="mb-3 px-0">
                                <v-list-item-content class="pt-1">
                                    <v-list-item-title class="font-weight-bold subtitle-1 text-rap-l2">{{ v.playlistName
                                    }}</v-list-item-title>
                                    <p class="mb-0 font-weight-regular subtitle-2">Updated {{ v.ago }}</p>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </v-card>
                </div>
                <div v-else>
                    <v-container fill-height>
                        <v-card class="elevation-0 mx-auto my-16">
                            <v-progress-circular size="80" indeterminate color="#1e53e5"></v-progress-circular>
                        </v-card>
                    </v-container>
                </div>

            </div>

            <div class="my-6 my-md-16">
                <v-toolbar dense class="elevation-0 mb-6 d-none d-md-block">
                    <div>
                        <p class="mb-0 font-weight-bold secondary-infont fs-32 lh-32">What's New?</p>
                        <p class="mb-0 title font-weight-regular"> Stay updated with our freshest videos straight from the channel.</p>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn to="/video" color="#0037B7" text class="text-capitalize"><span class="font-weight-medium">All our
                            videos</span></v-btn>
                </v-toolbar>
                <v-toolbar dense class="elevation-0 mb-6 d-md-none">
                    <div class="pl-2 pl-sm-4 pl-md-0">
                        <p class="mb-0 font-weight-bold secondary-infont fs-28">What's New?</p>
                        <p class="mb-0 fs-16 font-weight-regular"> Stay updated with our freshest videos straight from the channel.</p>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn to="/video" color="#0037B7" text class="text-capitalize"><span
                            class="fs-14 font-weight-medium">All our
                            videos</span></v-btn>
                </v-toolbar>

                <div class="pl-4">
                    <v-row v-if="homeitems.length > 0" no-glutters class="pt-2 pt-md-6">
                        <v-col v-for="(v, h) in homeitems" :key="h" cols="12" sm="4" xl="3" class="pb-0  pb-md-3">
                            <div class="hcard">
                            <v-card @click="videoShow(val = v)" data-aos="zoom-in-up" width="100%"
                                class="elevation-0 hcard pl-2 pl-sm-4 pr-6 pr-sm-8 px-md-0"
                                :class="h != homeitems.length - 1 ? 'mr-md-4' : 'mr-md-0'">
                                <div class="pos-rlt ">
                                    <v-card class="rounded-lg elevation-0" color="transparent">
                                        <img :src="v.stdurl" width="100%" :alt="v.stdurl">
                                    </v-card>
                                    <v-card color="#ffffff" class="elevation-0 rounded-md py-1 px-3 pos-abs abs-post2">
                                        <span class="font-weight-black subtitle-2">Explainer</span>
                                    </v-card>
                                </div>

                                <v-list-item data-aos="fade-left" class="px-0">
                                    <v-list-item-content class="pt-1">
                                        <v-list-item-title class="font-weight-bold subtitle-1 text-rap-l2">{{ v.title
                                        }}</v-list-item-title>
                                        <p class="mb-0 font-weight-regular subtitle-2">{{ v.view }} views <v-icon
                                                color="#666" size="6">mdi-checkbox-blank-circle</v-icon> {{ v.ago }}</p>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </div>
                        </v-col>
                    </v-row>
                    <div v-else>
                        <v-container fill-height>
                            <v-card class="elevation-0 mx-auto my-16">
                                <v-progress-circular size="80" indeterminate color="#1e53e5"></v-progress-circular>
                            </v-card>
                        </v-container>
                    </div>
                </div>

                <div class="py-6 py-md-10">
                    <v-row no-glutters class="pt-10">
                        <v-col md="2" class="pb-0 d-none d-md-block">
                            <v-container fill-height class="pb-0 ">
                                <div data-aos="fade-down" class="mb-6">
                                    <p class="font-weight-bold display-1">Quick   <br> Video <br> Bites</p>
                                    <p class="font-weight-bold">Short and sweet <br> investment trinkets</p>
                                    <v-btn to="/shorts" color="#0037B7" text class="text-none mt-3"><span
                                            class="font-weight-medium">View
                                            all shorts</span></v-btn>
                                </div>
                                <!-- <div class="mt-auto">
                                    <v-btn to="/shorts" color="#0037B7" text class="text-capitalize"><span
                                            class="font-weight-medium">View
                                            all shorts</span></v-btn>
                                </div> -->
                            </v-container>
                        </v-col>
                        <v-col cols="12" class="pb-0 d-md-none">
                            <v-toolbar dense class="elevation-0 mb-6">
                                <div class="pl-2 pl-sm-4 pl-md-0">
                                    <p class="mb-0 font-weight-bold secondary-infont fs-28">Quick video bites</p>
                                    <p class="mb-0 fs-16 font-weight-regular">Short and sweet investment trinkets.</p>
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn to="/shorts" color="#0037B7" text class="text-none"><span
                                        class="fs-14 font-weight-medium">View
                                        all shorts</span></v-btn>

                                        
                            </v-toolbar>
                        </v-col>
                        <v-col cols="12" md="10">
                            <div v-if="shortsitems.length > 0" class="pl-6 hover-containervid pl-sm-8 pl-md-2">
                                <v-btn @click="loveTo()" class="firstbtn hover-buttonvid left-button" icon fab outlined>
    <v-icon size="40">mdi-chevron-left</v-icon>
  </v-btn>
                                <v-card width="100%" id="love" v-dragscroll.x
                                    class="d-md-inline-flex overflow-x-auto cust-scroll rounded-lg elevation-0 d-none"
                                    color="transparent">
                                    <v-card data-aos="fade-left" v-for="(l, j) in shortsitems" :key="j"
                                        class="mb-6 hcard rounded-lg elevation-0"
                                        :class="j != shortsitems.length - 1 ? 'mr-4' : 'mr-0'" width="176px"
                                        @click="shortShow(val = l)">
                                        <v-card class="rounded-lg elevation-0" color="transparent">
                                            <img :src="l.stdurl" width="160px" height="328px" class="short-sty"
                                                :alt="l.stdurl">
                                        </v-card>
                                        <v-list-item class="px-0">
                                            <v-list-item-content>
                                                <v-list-item-title class="font-weight-bold subtitle-1 text-rap-l3">{{
                                                    l.title
                                                }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-card>
                                </v-card>
                                <v-card width="100%" v-dragscroll.x
                                    class="d-inline-flex overflow-x-auto cust-scroll rounded-lg elevation-0 d-md-none"
                                    color="transparent">
                                    <v-card data-aos="fade-left" v-for="(l, j) in shortsitems" :key="j"
                                        class="mb-2 rounded-lg elevation-0" width="142px"
                                        :class="j != shortsitems.length - 1 ? 'mr-4' : 'mr-0'" @click="shortShow(val = l)">
                                        <v-card class="rounded-lg elevation-0">
                                            <img :src="l.stdurl" width="142px" height="206px" class="short-sty"
                                                :alt="l.stdurl">
                                        </v-card>
                                        <v-list-item class="px-0">
                                            <v-list-item-content>
                                                <v-list-item-title class="font-weight-bold subtitle-1 text-rap-l3">{{
                                                    l.title
                                                }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-card>

                                </v-card>
                                <v-btn @click="loveBk()" class="firstbtn hover-buttonvid right-button" icon fab outlined>
    <v-icon size="40">mdi-chevron-right</v-icon>
  </v-btn>
                            </div>
                            <div v-else>
                                <v-container fill-height>
                                    <v-card class="elevation-0 mx-auto my-16">
                                        <v-progress-circular size="80" indeterminate color="#1e53e5"></v-progress-circular>
                                    </v-card>
                                </v-container>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>
        <div>
            <v-dialog v-model="videodialog" class="elevation-0 my-0" content-class="elevation-0">
                <v-container class="pa-md-0" fill-height>
                    <v-card color="transparent" class="video-frame rounded-md mx-0 mx-md-auto elevation-0 pb-0 mb-0">
                        <v-toolbar color="transparent" dense class="tool-dialog elevation-0">
                            <div>
                                <v-list-item class="pl-0 d-none d-md-block">
                                    <v-list-item-content class="pt-1">
                                        <v-list-item-title class="font-weight-bold title white--text">{{ playvideo ?
                                            playvideo.title : null }}</v-list-item-title>
                                        <p class="mb-0 white--text">{{ playvideo ? playvideo.view : null }} views <v-icon
                                                color="#fff" size="8">mdi-checkbox-blank-circle</v-icon> {{ playvideo ?
                                                    playvideo.ago : null }}</p>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="pl-0 d-md-none">
                                    <v-list-item-content class="pt-1">
                                        <v-list-item-title class="font-weight-bold fs-14 white--text">{{ playvideo ?
                                            playvideo.title : null }}</v-list-item-title>
                                        <p class="mb-0 white--text fs-12">{{ playvideo ? playvideo.view : null }} views
                                            <v-icon color="#fff" size="8">mdi-checkbox-blank-circle</v-icon> {{ playvideo ?
                                                playvideo.ago : null }}</p>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                            <v-spacer></v-spacer>
                            <v-icon @click="videoClose()" color="#ffffff">mdi-close</v-icon>
                        </v-toolbar>
                        <div>
                            <iframe class="rounded-lg video-dialog" type="text/html"
                                :src="`https://www.youtube.com/embed/${playvideo ? playvideo.videoid : null}?autoplay=1&amp;modestbranding=1`"
                                frameborder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                        <div class="pos-abs z-in1 trans-cent abs-post4">
                            <v-progress-circular v-if="videodialog" size="80" :width="4" indeterminate
                                color="#ffffff"></v-progress-circular>
                        </div>
                    </v-card>
                </v-container>
            </v-dialog>
            <v-dialog v-model="shortdialog" class="elevation-0 my-0" content-class="elevation-0">
                <v-card color="transparent" class="rounded-md mx-auto elevation-0 pb-0 mb-0 short-dialog">
                    <div class="pos-rlt">
                        <div class="pos-abs abs-post5 d-none d-md-block">
                            <v-btn :disabled="playshort ? playshort.id != 0 ? false : true : null" @click="shortPrev()"
                                @keyup.left="shortPrev()" medium color="#ffffff"
                                class="rounded-pill mr-3 text-none my-auto brd-2" outlined>Previous
                                short</v-btn>
                        </div>
                        <div>
                            <v-toolbar color="transparent" heigth="32px" dense class="tool-dialog elevation-0">
                                <v-spacer></v-spacer>
                                <v-icon @click="shortClose()" color="#ffffff">mdi-close</v-icon>
                            </v-toolbar>
                            <iframe class="rounded-lg short-frame" type="text/html" width="100%"
                                :src="`https://www.youtube.com/embed/${playshort ? playshort.videoid : null}?autoplay=1&amp;modestbranding=1`"
                                frameborder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                        <v-row no-glutters class="d-md-none">
                            <v-col cols="6">
                                <v-btn :disabled="playshort ? playshort.id != 0 ? false : true : null" @click="shortPrev()"
                                    @keyup.left="shortPrev()" medium color="#ffffff" block
                                    class="rounded-pill text-none my-auto brd-2" outlined>Previous
                                    short</v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn :disabled="playshort ? playshort.id != 23 ? false : true : null" @click="shortNext()"
                                    @keyup.right="shortNext()" medium color="#ffffff" block
                                    class="rounded-pill text-none my-auto brd-2" outlined>Next
                                    short</v-btn>
                            </v-col>
                        </v-row>
                        <div class="pos-abs z-in1 trans-cent abs-post4">
                            <v-progress-circular v-if="shortdialog" size="80" :width="4" indeterminate
                                color="#ffffff"></v-progress-circular>
                        </div>
                        <div class="pos-abs abs-post6 d-none d-md-block">
                            <v-btn :disabled="playshort ? playshort.id != 23 ? false : true : null" @click="shortNext()"
                                @keyup.right="shortNext()" medium color="#ffffff"
                                class="rounded-pill ml-3 text-none my-auto brd-2" outlined>Next
                                short</v-btn>
                        </div>
                    </div>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { sessapi } from '../../apiurl';
import { dragscroll } from 'vue-dragscroll'


export default {
    directives: {
        dragscroll
    },
    data: () => ({
        playshort: null,
        playvideo: null,
        videodialog: false,
        shortdialog: false,
        homeitems: [],
        shortsitems: [],
        page: 1,
        shortpage: 1,

        pagecount: 0,
        shortpagecount: 0,
        allvideos: [],
        allshort: [],

        shortlists: [],

        homeplaylist: [],
        allplaylist: [],
    }),
    mounted() {
        this.getPlaylist();
        this.getVideos();
        this.getShort();
        this.shortClose();
        this.videoClose();
    },
    methods: {
        getVideos() {
            this.allvideos = []
            let config = {
                method: 'post',
                url: `${sessapi}/getallvideos?pagecount=${this.page}&pageSize=24`,
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    if (response.data.data) {
                        let data = response.data.data
                        for (let v = 0; v < data.length; v++) {
                            data[v]['isdate'] = `${new Date(data[v].uploadtime).toDateString().slice(3)} ${new Date(data[v].uploadtime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                            data[v]['ago'] = axiosThis.differentDate(data[v].uploadtime);
                            data[v]['view'] = axiosThis.differentView(Number(data[v].viewCount));
                            axiosThis.allvideos.push(data[v]);
                        }
                        axiosThis.homeitems = axiosThis.allvideos.slice(0, 9)
                        axiosThis.pagecount = response.data.TotalPages;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getShort() {
            this.allshort = []
            let config = {
                method: 'post',
                url: `${sessapi}/getallshorts?pagecount=${this.shortpage}&pageSize=24`,
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    if (response.data.data) {
                        let data = response.data.data
                        for (let v = 0; v < data.length; v++) {
                            data[v]['isdate'] = `${new Date(data[v].uploadtime).toDateString().slice(3)} ${new Date(data[v].uploadtime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                            data[v]['ago'] = axiosThis.differentDate(data[v].uploadtime);
                            data[v]['view'] = axiosThis.differentView(Number(data[v].viewCount));
                            data[v]['id'] = v;
                            axiosThis.allshort.push(data[v]);
                        }
                        axiosThis.shortsitems = axiosThis.allshort
                        axiosThis.shortpagecount = response.data.TotalPages;
                        window.scrollTo(0, 0);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getPlaylist() {
            this.allplaylist = [];
            let config = {
                method: 'get',
                // url: `${sessapi}/getallplaylist`,
                url: `${sessapi}/getallplaylist?pagesize=24&pagecount=${this.page}`,
                // url: `http://192.168.5.70:2001/getallplaylist`,
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    if (response.data.data) {
                        let data = response.data.data
                        for (let v = 0; v < data.length; v++) {
                            data[v]['isdate'] = `${new Date(data[v].date).toDateString().slice(3)} ${new Date(data[v].date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                            data[v]['ago'] = axiosThis.differentDate(data[v].date);
                            axiosThis.allplaylist.push(data[v]);
                        }
                        axiosThis.homeplaylist = axiosThis.allplaylist.slice(0, 8)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        },
        differentView(view) {
            return `${view > 999 ? Math.round(view / 1000) : view > 999999 ? Math.round(view / 1000000) : view > 999999999 ? Math.round(view / 1000000000) : view} ${view > 999 ? 'K' : view > 999999 ? 'M' : view > 999999999 ? 'B' : ''}`
        },
        differentDate(date) {
            var date1 = new Date(date);
            var date2 = new Date();
            var ov = Math.abs(date2.getTime() - date1.getTime())
            var mt = Math.round(ov / 3600000)
            var dd = mt > 60 ? Math.round(mt / 24) : 0;
            var mm = dd > 30.4166667 ? Math.round(dd / 30.4166667) : 0;
            var yy = mm > 12 ? Math.round(dd / 365) : 0;
            return `${yy != 0 ? yy : mm != 0 ? mm : dd != 0 ? dd : mt != 0 ? mt : 0} ${yy != 0 ? yy > 1 ? 'years' : 'year' : mm != 0 ? mm > 1 ? 'months' : 'month' : dd != 0 ? dd > 1 ? 'days' : 'day' : mt > 1 ? 'hours' : 'minutes'} ago`;
        },

        shortShow(val) {
            this.playshort = val;
            this.shortdialog = true;
        },
        shortNext() {
            let ids = this.allshort[this.playshort.id + 1];
            this.playshort = null;
            this.playshort = ids;
        },
        shortPrev() {
            let ids = this.allshort[this.playshort.id - 1];
            this.playshort = null;
            this.playshort = ids;
        },
        videoShow(val) {
            this.playvideo = val;
            this.videodialog = true;
        },
        shortClose() {
            this.playshort = [];
            this.shortdialog = false;
        },
        videoClose() {
            this.videodialog = false;
            this.playvideo = [];
        },
        playPlaylist(v) {
            this.$router.push({ name: 'playlist', params: v });
        },
        loveTo() {
      const element = document.getElementById("love");
      element.scrollBy({
        left: -600,
        behavior: "smooth",
      });
    },

    loveBk() {
      const element = document.getElementById("love");
      element.scrollBy({
        left: 600,
        behavior: "smooth",
      });
    },
    }
}
</script>